import { useEffect, useRef } from 'react';
import { matchPath } from 'react-router-dom';

export const getBoxShadowStringFromElevation = (elevation) =>
  elevation.toString().replace('box-shadow: ', '').replace(';', '');

export const currencyFormatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
});

export const getParamFromMatchPatch = ({ location, path, paramKey }) => {
  if (!location || typeof location.pathname !== 'string') {
    console.error('Invalid location object:', location);
    return null;
  }

  const matched = matchPath(path, location.pathname);

  return matched?.params?.[paramKey] || null;
};

export const previousState = (state) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = state;
  });
  return ref.current;
};
