export const DEFAULT_FILTER = {
  enabled: 'true',
  inStock: 'true',
};

const batchesState = {
  refreshKey: 0,
  context: null,
  filters: DEFAULT_FILTER,
  isBatchesUpdating: false,
  isStockLocations: false,
  stockLocations: null,
  isCategoriesLoading: false,
  categories: null,
  variantTemplates: null,
  isModalLoading: false,
  template: null,
  downloadedTemplate: null,
  isTemplateLoading: false,
  variantSelectedProducts: {},
};

export default batchesState;
