import { createSlice } from '@reduxjs/toolkit';
import productsState from '../states/productsState';
import {
  setIsProductUpdating,
  updateProducts,
} from '../actions/productsActions';

export const productsSlice = createSlice({
  name: 'products',
  initialState: productsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateProducts.pending, (state) => ({
      ...state,
      isProductUpdating: true,
    }));

    builder.addCase(updateProducts.fulfilled, (state) => ({
      ...state,
      refreshKey: state.refreshKey + 1,
      isProductUpdating: false,
    }));

    builder.addCase(updateProducts.rejected, (state) => ({
      ...state,
      isProductUpdating: false,
    }));

    builder.addCase(setIsProductUpdating, (state, action) => ({
      ...state,
      isProductUpdating: action.payload,
    }));
  },
});
