import {
  neutral0,
  neutral10,
  pxToRem,
  spacing3,
  spacing7,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';
import { MAX_GRID_WIDTH } from '../../constants';

export const ListViewHeader = styled.div`
  display: flex;
  width: 100%;
  max-width: ${pxToRem(MAX_GRID_WIDTH)};
  height: ${pxToRem(52)};
  align-items: center;
  border: 1px solid ${neutral10.hex};
  border-radius: 10px;
  background-color: ${neutral0.hex};
  margin: 0 auto ${spacing3};
`;

export const LoadMoreWrapper = styled.div`
  margin-bottom: ${spacing7};
`;
