import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './reducers/rootReducer';
import { createDebouncedUndoMiddleware } from './utils/undoable';
import { integrationsApi } from './queries/integrations';
import { vendorApi } from './queries/vendor';
import { advancedAnalyticsApi } from './queries/advancedAnalytics';

export const setupStore = (preloadedState) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(createDebouncedUndoMiddleware(['inventoryPricing']))
        .concat(integrationsApi.middleware)
        .concat(vendorApi.middleware)
        .concat(advancedAnalyticsApi.middleware),
  });
