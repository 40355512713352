export const featuredVendorCardRenderedEvent = ({ advertisement }) => {
  window.dataLayer.push({
    event: 'featured_vendor_card_rendered',
    vendor_id: advertisement.vendor_id,
    vendor_name: advertisement.vendor_name,
    advertisement_id: advertisement.id,
    dispensary_name: advertisement.dispensary_name || "",
  });
};

export const featuredVendorCardClickEvent = ({ advertisement }) => {
  window.dataLayer.push({
    event: 'featured_vendor_card_click',
    vendor_id: advertisement.vendor_id,
    vendor_name: advertisement.vendor_name,
    advertisement_id: advertisement.id,
    dispensary_name: advertisement.dispensary_name || "",
  });
};

export const featuredVendorAllCardClickEvent = () => {
  window.dataLayer.push({
    event: 'featured_vendor_all_card_click',
  });
};

export const featuredBrandCardRenderedEvent = ({ brandAdvertisement }) => {
  window.dataLayer.push({
    event: 'featured_brand_card_rendered',
    brand_id: brandAdvertisement.brand_id,
    brand_name: brandAdvertisement.name,
    advertisement_id: brandAdvertisement.id,
    dispensary_name: brandAdvertisement.dispensary_name || "",

  });
};

export const featuredBrandCardClickEvent = ({ brandAdvertisement }) => {
  window.dataLayer.push({
    event: 'featured_brand_card_click',
    brand_id: brandAdvertisement.brand_id,
    brand_name: brandAdvertisement.name,
    advertisement_id: brandAdvertisement.id,
    dispensary_name: brandAdvertisement.dispensary_name || "",
  });
};

export const featuredVendorFavoriteToggleEvent = ({
  toggledValue,
  advertisement,
}) => {
  window.dataLayer.push({
    event: 'featured_vendor_favorite_toggle',
    favorite_toggled_value: toggledValue,
    vendor_id: advertisement.id,
    vendor_name: advertisement.name,
    advertisement_id: advertisement.id,
    dispensary_name: advertisement.dispensary_name || "",
  });
};

export const sponsoredAdViewEvent = ({ sponsoredAd }) => {
  window.dataLayer.push({
    event: 'sponsored_ad_view',
    advertisement_id: sponsoredAd.id || '',
    sponsored_ad_id: sponsoredAd.sponsorship_id || '',
    sponsored_ad_name: sponsoredAd.name || '',
    sponsored_ad_target: sponsoredAd.app_target || sponsoredAd.target_url || '',
    vendor_id: sponsoredAd.vendor_id || '',
    vendor_name: sponsoredAd.vendor_name || '',
    dispensary_name: sponsoredAd.dispensary_name || "",
  });
};

export const sponsoredAdClickEvent = ({ sponsoredAd }) => {
  window.dataLayer.push({
    event: 'sponsored_ad_click',
    advertisement_id: sponsoredAd.id || '',
    sponsored_ad_id: sponsoredAd.sponsorship_id || '',
    sponsored_ad_name: sponsoredAd.name || '',
    sponsored_ad_target: sponsoredAd.app_target || sponsoredAd.target_url || '',
    vendor_id: sponsoredAd.vendor_id || '',
    vendor_name: sponsoredAd.vendor_name || '',
    dispensary_name: sponsoredAd.dispensary_name || "",
  });
};

export const exploreAllSellersCardClickEvent = () => {
  window.dataLayer.push({
    event: 'explore_all_sellers_card_click',
  });
};

export const shopFlowerCardClickEvent = () => {
  window.dataLayer.push({
    event: 'shop_flower_card_click',
  });
};

export const shopAncillaryCardClickEvent = () => {
  window.dataLayer.push({
    event: 'shop_ancillary_card_click',
  });
};

export const favoriteVendorClickEvent = ({ vendor }) => {
  window.dataLayer.push({
    event: 'favorite_vendor_click',
    vendor_id: vendor.id,
    vendor_name: vendor.name,
  });
};

export const favoriteVendorsAllClickEvent = () => {
  window.dataLayer.push({
    event: 'favorite_vendors_all_click',
  });
};
