import {
  Select,
  Typography,
  pxToRem,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import { setSelectedVariant } from '../../../../store/actions/storefrontActions';

const ProductDetailsVariantSelect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productDetails, vendor } = useStorefrontState();
  const { selectedVariant } = productDetails;
  const { storefront_aggregate_variants: storefrontAggregateVariants } =
    vendor.settings.settingsDeprecated;

  const variantNameBuilder = (variant) =>
    variant.stock_record.batch
      ? `${variant.name}${
          variant.case_size > 1 ? `, case of ${variant.case_size}` : ''
        } - ${variant.stock_record?.batch?.batch_ref}`
      : `${variant.verbose_name} ${
          variant.storefront_display_price !== null &&
          `- ${variant.storefront_display_price}`
        }`;

  const buildVariantOptions = (variants) =>
    variants.map((variant) => ({
      label: variantNameBuilder(variant),
      value: variant.id,
      level: 1,
      selected: variant.id === selectedVariant.id,
      key: variant.id,
    }));

  const variantOptions = buildVariantOptions(
    storefrontAggregateVariants
      ? productDetails.uniqueVariants
      : productDetails.variants
  );

  const handleVariantChange = ({ value }) => {
    const newSelectedVariant = productDetails.variants.find(
      (variant) => variant.id.toString() === value.toString()
    );
    dispatch(setSelectedVariant(newSelectedVariant));
    navigate(`?variant=${value}`, { replace: true });
  };

  return (
    <>
      {variantOptions.length > 1 ? (
        <Select
          options={variantOptions}
          onSelect={handleVariantChange}
          shape='rounded'
          sx={{
            width: pxToRem(350),
            '& > div': {
              zIndex: 1,
            },
          }}
          testingId='product-details-variant-select--component--select'
          value={productDetails.selectedVariantId}
          variant='secondary'
        />
      ) : (
        <Typography>{variantOptions[0].label}</Typography>
      )}
    </>
  );
};

export default ProductDetailsVariantSelect;
