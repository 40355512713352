import camelize from 'camelize';
import { createApi } from '@reduxjs/toolkit/query/react';
import { createEntityAdapter } from '@reduxjs/toolkit';
import {
  newToastNotification,
  ToastType,
} from '../../dashboard/components/ToastNotifications/toasts';
import joinArray from '../../utils/joinArray';
import { serializeFilters, serializeOrdering } from '../../utils/serialization';
import { camelToSnake } from '../../dashboard/utils';
import { baseQueryWithToast } from './base';

const inventoryAdapter = createEntityAdapter({
  selectId: (entry) => entry.variant_pk,
});

const initialState = inventoryAdapter.getInitialState();

export const vendorApi = createApi({
  reducerPath: 'vendor',
  baseQuery: baseQueryWithToast,
  endpoints: (builder) => ({
    getBatches: builder.query({
      query: ({ filters, page, pageSize, vendorId, ordering }) => {
        const sortMap = {
          products: 'products__id',
          type: 'bucket_weight',
          attachments: 'attachments_count',
        };
        return {
          url: `api/vendor/${vendorId}/batches/?${serializeFilters(
            camelToSnake(filters),
            { sample_type: 'commaDelimited' }
          )}`,
          method: 'GET',
          params: {
            page_number: page,
            page_size: pageSize,
            ordering: serializeOrdering(
              ordering.map((i) => ({ ...i, id: sortMap[i.id] || i.id }))
            ),
          },
        };
      },
      transformResponse: (response) => response,
    }),

    getBrands: builder.query({
      query: ({ vendorId }) => `api/vendor/${vendorId}/brands/`,
      transformResponse: (response) => response.brands,
    }),

    getCategories: builder.query({
      query: ({ vendorId }) => `api/vendor/${vendorId}/categories/`,
      transformResponse: (response) => response.categories,
    }),

    getStockLocations: builder.query({
      query: ({ vendorId, params }) => ({
        url: `vendor/${vendorId}/stock-locations/`,
        params,
      }),
      transformResponse: (response) => response.stock_locations,
    }),

    getProductAttributes: builder.query({
      query: ({ vendorId }) => ({
        url: `api/vendor/${vendorId}/products/product-attributes`,
        params: {
          slug: 'sample',
        },
      }),
      transformResponse: (response) => {
        if (response.results && response.results.length > 0) {
          return response.results[0].values;
        }
        return [];
      },
    }),

    getInventoryPrices: builder.query({
      query: ({ vendorId, page, pageSize, ordering, filters }) => ({
        url: `vendor/${vendorId}/products/inventory-prices/?${serializeFilters(
          filters,
          { sample_type: 'commaDelimited' }
        )}`,
        method: 'GET',
        params: {
          page,
          page_size: pageSize,
          ordering: serializeOrdering(ordering),
        },
      }),
      transformResponse: (response) => {
        const normalized = inventoryAdapter.setAll(
          initialState,
          response.results
        );

        return {
          ...response,
          entities: normalized.entities,
          ids: normalized.ids,
        };
      },
      merge: (currentCache, newItems) => {
        currentCache.entities = {
          ...currentCache.entities,
          ...newItems.entities,
        };
        currentCache.ids = newItems.ids;
        currentCache.count = newItems.count;
        currentCache.next = newItems.next;
        currentCache.previous = newItems.previous;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: 'InventoryEntry', id })),
              { type: 'InventoryList' },
            ]
          : [{ type: 'InventoryList' }],
    }),

    getLogEntries: builder.query({
      query: ({ filters, page, pageSize, vendorId }) => ({
        url: `api/vendor/${vendorId}/log-entries/?${serializeFilters(
          camelToSnake(filters)
        )}`,
        method: 'GET',
        params: {
          page,
          page_size: pageSize,
        },
      }),
      transformResponse: camelize,
    }),

    getProducts: builder.query({
      query: ({ filters, page, pageSize, vendorId, ordering }) => {
        const sortMap = {
          categories: 'categories__name',
          brands: 'brands__name',
        };
        return {
          url: `api/vendor/${vendorId}/products/?${serializeFilters(
            camelToSnake(filters),
            { sample_type: 'commaDelimited' }
          )}`,
          method: 'GET',
          params: {
            page_number: page,
            page_size: pageSize,
            ordering: serializeOrdering(
              ordering.map((i) => ({ ...i, id: sortMap[i.id] || i.id }))
            ),
          },
        };
      },
      transformResponse: (response) => response,
    }),

    getSettings: builder.query({
      query: ({ vendorId, settings }) => ({
        url: `api/vendor/${vendorId}/settings/`,
        method: 'GET',
        params: {
          setting: settings,
        },
      }),
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        return `${endpointName}-${queryArgs.vendorId}`;
      },
      merge: (currentCache, newItems) => {
        return { ...currentCache, ...newItems };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        if (!previousArg) return true;

        const newSettings = currentArg.settings.filter(
          (setting) => !previousArg.settings.includes(setting)
        );

        return newSettings.length > 0;
      },
    }),

    getStrains: builder.query({
      query: ({ vendorId }) => `api/vendor/${vendorId}/strains/`,
      transformResponse: (response) => response,
    }),

    getTags: builder.query({
      query: ({ vendorId }) => `api/vendor/${vendorId}/tags/`,
      transformResponse: (response) => response.tags,
    }),

    saveInventory: builder.mutation({
      query: ({ vendorId, changes }) => ({
        url: `vendor/${vendorId}/products/inventory-prices/multiple_update/`,
        method: 'PATCH',
        body: {
          variants: Object.keys(changes).map((key) => ({
            variant_pk: key,
            ...changes[key],
          })),
        },
      }),
      async onQueryStarted(
        { changes },
        { dispatch, queryFulfilled, getState }
      ) {
        try {
          const { data } = await queryFulfilled;
          const { errors = [] } = data;

          if (errors.length) {
            newToastNotification({
              body: joinArray(errors),
              toastType: ToastType.ERROR,
            });
          } else {
            const matchingQueries = vendorApi.util.selectInvalidatedBy(
              getState(),
              [{ type: 'InventoryList' }]
            );

            matchingQueries.forEach(({ endpointName, originalArgs }) => {
              if (endpointName === 'getInventoryPrices') {
                dispatch(
                  vendorApi.util.updateQueryData(
                    'getInventoryPrices',
                    originalArgs,
                    (draft) => {
                      Object.entries(changes).forEach(
                        ([variantPk, updates]) => {
                          if (draft.entities[variantPk]) {
                            Object.assign(draft.entities[variantPk], updates);
                          }
                        }
                      );
                    }
                  )
                );
              }
            });

            newToastNotification({
              body: 'Inventory is successfully updated!',
              toastType: ToastType.SUCCESS,
            });
          }
        } catch {
          // error handled in baseQuery
        }
      },
    }),
  }),
});

export const {
  useGetBatchesQuery,
  useLazyGetBrandsQuery,
  useLazyGetCategoriesQuery,
  useLazyGetStockLocationsQuery,
  useLazyGetProductAttributesQuery,
  useLazyGetStrainsQuery,
  useLazyGetTagsQuery,
  useGetInventoryPricesQuery,
  useGetLogEntriesQuery,
  useGetProductsQuery,
  useGetSettingsQuery,
  useSaveInventoryMutation,
} = vendorApi;
