import queryString from 'query-string';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { BQ_DEBUG, STOREFRONT_MODE } from '../../constants';
import { useStorefrontState } from '../../../hooks/useReduxState';
import {
  getPublicStorefrontData,
  getQuickOrderProducts,
  getSellerStorefrontData,
  setSellerGridView,
} from '../../../store/actions/storefrontActions';
import {
  createCheckboxFilter,
  createRangeFilter,
} from '../../../utils/filters';
import {
  PageHeaderSearchStyleSortContainer,
  PageHeaderStyleSortContainer,
} from '../PageHeader/PageHeader.styles';
import PageHeaderBanner from '../PageHeader/PageHeaderBanner';
import PageHeaderFilters from '../PageHeader/PageHeaderFilters';
import PageHeaderHero from '../PageHeader/PageHeaderHero';
import PageHeaderInfo from '../PageHeader/PageHeaderInfo';
import PageHeaderSearch from '../PageHeader/PageHeaderSearch';
import PageHeaderSort from '../PageHeader/PageHeaderSort';
import PageHeaderStyleToggle from '../PageHeader/PageHeaderStyleToggle';

const SellerHeader = ({ isQuickOrder }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { hasLimitedAccess, publicMenu, sellerPageData, vendor } =
    useStorefrontState();
  const {
    bannerText,
    bannerTitle,
    billingStatus,
    brands,
    categories,
    description,
    heroImage,
    heroImageLinkTarget,
    id,
    isFavorite,
    logo,
    maxPrice,
    name,
    phenotypes,
    productTypes,
    regions,
    slug,
    strains,
    tags,
    terpeneTypes,
  } = publicMenu?.products ? publicMenu : sellerPageData;
  const { batchQueuesEnabled, isGridView, settingsDeprecated } =
    vendor.settings;

  const queryParamsParsed = queryString.parse(location.search);

  const handleSellerQuery = (sellerQuery) => {
    if (isQuickOrder) {
      dispatch(getQuickOrderProducts({ slug, queryString: sellerQuery }));
    } else if (publicMenu?.products) {
      dispatch(
        getPublicStorefrontData({
          slug,
          queryString: sellerQuery,
        })
      );
    } else {
      dispatch(getSellerStorefrontData({ slug, queryString: sellerQuery }));
    }
  };

  const handleStyleToggle = (isGridView) => {
    dispatch(setSellerGridView(isGridView));
  };

  const handleQueryChange = (query = '') => {
    navigate(`${location.pathname}?${query}`, { replace: true });
    handleSellerQuery(query);
  };

  const buildSortOptions = () => {
    let options = [
      {
        key: 'name',
        value: 'name',
        label: 'Name: A-Z',
        level: 1,
        selected: queryParamsParsed.sort === 'name',
      },
      {
        key: '-name',
        value: '-name',
        label: 'Name: Z-A',
        level: 1,
        selected: queryParamsParsed.sort === '-name',
      },
      {
        key: 'price',
        value: 'price',
        label: 'Price: Low to High',
        level: 1,
        selected: queryParamsParsed.sort === 'price',
      },
      {
        key: '-price',
        value: '-price',
        label: 'Price: High to Low',
        level: 1,
        selected: queryParamsParsed.sort === '-price',
      },
      {
        key: 'categories',
        value: 'categories',
        label: 'Categories: A-Z',
        level: 1,
        selected: queryParamsParsed.sort === 'categories',
      },
      {
        key: '-categories',
        value: '-categories',
        label: 'Categories: Z-A',
        level: 1,
        selected: queryParamsParsed.sort === '-categories',
      },
      {
        key: 'strains',
        value: 'strains',
        label: 'Strains: A-Z',
        level: 1,
        selected: queryParamsParsed.sort === 'strains',
      },
      {
        key: '-strains',
        value: '-strains',
        label: 'Strains: Z-A',
        level: 1,
        selected: queryParamsParsed.sort === '-strains',
      },
      {
        key: 'phenotypes',
        value: 'phenotypes',
        label: 'Phenotypes: A-Z',
        level: 1,
        selected: queryParamsParsed.sort === 'phenotypes',
      },
      {
        key: '-phenotypes',
        value: '-phenotypes',
        label: 'Phenotypes: Z-A',
        level: 1,
        selected: queryParamsParsed.sort === '-phenotypes',
      },
      {
        key: 'brands',
        value: 'brands',
        label: 'Brands: A-Z',
        level: 1,
        selected: queryParamsParsed.sort === 'brands',
      },
      {
        key: '-brands',
        value: '-brands',
        label: 'Brands: Z-A',
        level: 1,
        selected: queryParamsParsed.sort === '-brands',
      },
    ];
    if (regions.length === 1) {
      options = options.concat([
        {
          key: 'thc',
          value: 'thc',
          label: 'THC: Low to High',
          level: 1,
          selected: queryParamsParsed.sort === 'thc',
        },
        {
          key: '-thc',
          value: '-thc',
          label: 'THC: High to Low',
          level: 1,
          selected: queryParamsParsed.sort === '-thc',
        },
        {
          key: 'cbd',
          value: 'cbd',
          label: 'CBD: Low to High',
          level: 1,
          selected: queryParamsParsed.sort === 'cbd',
        },
        {
          key: '-cbd',
          value: '-cbd',
          label: 'CBD: High to Low',
          level: 1,
          selected: queryParamsParsed.sort === '-cbd',
        },
      ]);
    }
    return options;
  };

  const filterOptions = [];
  if (productTypes.length > 0) {
    filterOptions.push(
      createCheckboxFilter({
        data: productTypes.map((t) => ({
          id: t.id,
          name: t.name.split(' ')[0],
        })),
        handleQueryChange,
        key: 'product_types',
        name: 'Product Type',
        queryParamsParsed,
      })
    );
  }
  if (categories.length > 0) {
    filterOptions.push(
      createCheckboxFilter({
        data: categories,
        handleQueryChange,
        key: 'categories',
        name: 'Category',
        queryParamsParsed,
      })
    );
  }
  if (phenotypes.length > 0) {
    filterOptions.push(
      createCheckboxFilter({
        data: phenotypes.map((phenotype) => ({
          id: phenotype,
          name: phenotype,
        })),
        handleQueryChange,
        key: 'phenotypes',
        name: 'Phenotype',
        queryParamsParsed,
      })
    );
  }
  if (strains.length > 0) {
    filterOptions.push(
      createCheckboxFilter({
        data: strains,
        handleQueryChange,
        key: 'strains',
        name: 'Strain',
        queryParamsParsed,
      })
    );
  }
  if (brands.length > 0) {
    filterOptions.push(
      createCheckboxFilter({
        data: brands,
        handleQueryChange,
        key: 'brands',
        name: 'Brand',
        queryParamsParsed,
      })
    );
  }
  if (terpeneTypes?.length > 0) {
    filterOptions.push(
      createCheckboxFilter({
        data: terpeneTypes.map(({ name, slug }) => ({
          id: slug,
          name,
        })),
        handleQueryChange,
        key: 'terpenes',
        name: 'Terpene',
        queryParamsParsed,
      })
    );
  }
  if (tags?.length > 0) {
    filterOptions.push(
      createCheckboxFilter({
        data: tags.map((tag) => ({
          id: tag,
          name: tag,
        })),
        handleQueryChange,
        key: 'tags',
        name: 'Spotlight',
        queryParamsParsed,
      })
    );
  }

  if (!batchQueuesEnabled) {
    if (maxPrice > 0) {
      filterOptions.push(
        createRangeFilter({
          handleQueryChange,
          key: 'price',
          label: 'Price',
          range: {
            initialMinValue: queryParamsParsed?.price?.split('-')[0] || 0,
            initialMaxValue:
              queryParamsParsed?.price?.split('-')[1] || maxPrice || 0,
            min: 0,
            max: maxPrice,
          },
          name: 'Price',
          queryParamsParsed,
        })
      );
    }
    if (regions.length === 1) {
      filterOptions.push(
        createRangeFilter({
          handleQueryChange,
          key: 'THC',
          label: 'THC',
          range: {
            initialMinValue: queryParamsParsed?.THC?.split('-')[0] || 0,
            initialMaxValue: queryParamsParsed?.THC?.split('-')[1] || 100,
            min: 0,
            max: 100,
          },
          name: 'THC Potency',
          queryParamsParsed,
        })
      );
      filterOptions.push(
        createRangeFilter({
          handleQueryChange,
          key: 'CBD',
          label: 'CBD',
          range: {
            initialMinValue: queryParamsParsed?.CBD?.split('-')[0] || 0,
            initialMaxValue: queryParamsParsed?.CBD?.split('-')[1] || 100,
            min: 0,
            max: 100,
          },
          name: 'CBD Potency',
          queryParamsParsed,
        })
      );
    }
  }

  return (
    <>
      {!isQuickOrder && (
        <>
          {heroImage && (
            <PageHeaderHero
              heroImage={heroImage}
              heroImageLinkTarget={heroImageLinkTarget}
              name={name}
            />
          )}
          <PageHeaderInfo
            description={description}
            hasFavorite={!hasLimitedAccess}
            isFavorite={isFavorite}
            imgSrc={logo}
            slug={slug}
            title={name || ''}
            vendorId={id}
          />
          {!hasLimitedAccess && !billingStatus === 'past_due' && (
            <PageHeaderBanner
              bannerSeverity='error'
              bannerText={[
                'This Seller prevents Buyers from placing orders when their billing account is past due.',
                'Please reach out to the Seller to resolve any outstanding payment issues before placing an order.',
              ]}
              bannerTitle='Past Due Notice'
            />
          )}
          {(settingsDeprecated.storefront_mode ===
            STOREFRONT_MODE.COMING_SOON ||
            settingsDeprecated.storefront_mode ===
              STOREFRONT_MODE.INACTIVE) && (
            <PageHeaderBanner
              bannerSeverity='warning'
              bannerText='This storefront is currently hidden from customers. Update your storefront mode to “Accepting Orders” or “Read Only” to enable visibility'
              bannerTitle='Maintenance Mode'
            />
          )}
          {!publicMenu?.products && (bannerText || bannerTitle) && (
            <PageHeaderBanner
              bannerText={bannerText}
              bannerTitle={bannerTitle}
            />
          )}
          {location.search.includes(BQ_DEBUG) && (
            <PageHeaderBanner
              bannerText={
                batchQueuesEnabled
                  ? 'Batch Queues Enabled'
                  : 'Batch Queues Disabled'
              }
              bannerTitle='BQ Debug'
            />
          )}
        </>
      )}
      <PageHeaderSearchStyleSortContainer>
        <PageHeaderSearch
          onSearch={handleSellerQuery}
          placeholder='Search Products'
        />
        <PageHeaderStyleSortContainer>
          <PageHeaderStyleToggle
            isGridView={isGridView}
            onToggle={handleStyleToggle}
          />
          <PageHeaderSort
            onSort={handleSellerQuery}
            sortOptions={buildSortOptions()}
          />
        </PageHeaderStyleSortContainer>
      </PageHeaderSearchStyleSortContainer>
      <PageHeaderFilters filterOptions={filterOptions} />
    </>
  );
};

export default SellerHeader;
