import {
  Button,
  IconLoadingSpinner,
  Select,
  Typography,
  bodyLargeTypography,
  logoDefaultColorBlack,
  pxToRem,
  spacing3,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { setLocationFromDispensarySelection } from '../../../../store/actions/storefrontActions';
import { ShopAsModalBase, ShopAsModalContent } from './ShopAsModal.styles';

const ShopAsModal = ({ dispensaries, isGlobalAdmin, vendors }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeepLink, setIsDeepLink] = useState(false);
  const [deepLink, setDeepLink] = useState({
    pathname: '',
    search: '',
    hash: '',
  });
  const formedDispensarySelectOptions = dispensaries.map((dispensary) => ({
    level: 1,
    label: dispensary.name,
    value: dispensary.id.toString(),
    key: dispensary.id,
  }));

  const formedVendorSelectOptions = vendors.map((vendors) => ({
    level: 1,
    label: vendors.name,
    value: vendors.id.toString(),
    key: vendors.id,
  }));

  useEffect(() => {
    if (location.pathname !== '/') {
      setIsDeepLink(true);
      setDeepLink(location);
    }
  }, [location]);

  const selectDispensaryLabel = isDeepLink
    ? 'Please Select A Dispensary To View This Page As'
    : 'Please Select A Dispensary To Shop As';

  return (
    <ShopAsModalBase data-testid='dispensary-selection-modal--component--div'>
      <ShopAsModalContent>
        <img src={logoDefaultColorBlack} alt='Leaf Trade' />
        {isLoading ? (
          <IconLoadingSpinner />
        ) : (
          <>
            {dispensaries.length > 0 && (
              <Select
                label={selectDispensaryLabel}
                options={formedDispensarySelectOptions}
                onSelect={({ value }) => {
                  setIsLoading(true);
                  dispatch(
                    setLocationFromDispensarySelection({
                      dispensaryId: value,
                      onSuccess: () => {
                        setIsLoading(false);
                        if (isDeepLink) {
                          navigate(deepLink);
                        } else {
                          navigate('/');
                        }
                      },
                    })
                  );
                }}
                sx={{
                  width: pxToRem(275),
                  '& > div': {
                    zIndex: 1,
                  },
                }}
              />
            )}
            {dispensaries.length > 0 && vendors.length > 0 && !isDeepLink && (
              <Typography
                bodyStyle={bodyLargeTypography}
                sx={{
                  margin: `${spacing3} 0`,
                }}
              >
                - OR -
              </Typography>
            )}
            {vendors.length > 0 && !isDeepLink && (
              <Select
                label='Please Select A Vendor To Manage'
                options={formedVendorSelectOptions}
                onSelect={(selectedOption) => {
                  setIsLoading(true);
                  window.location.href = `/dashboard/vendor/${selectedOption.value}/`;
                }}
                sx={{
                  width: pxToRem(250),
                  '& > div': {
                    zIndex: 1,
                  },
                }}
              />
            )}
            {(dispensaries.length > 0 || vendors.length > 0) &&
              isGlobalAdmin &&
              !isDeepLink && (
                <Typography
                  bodyStyle={bodyLargeTypography}
                  sx={{
                    margin: `${spacing3} 0`,
                  }}
                >
                  - OR -
                </Typography>
              )}
            {isGlobalAdmin && !isDeepLink && (
              <Button
                onClick={() => {
                  setIsLoading(true);
                  window.location.href = '/dashboard/';
                }}
                shape='pill'
                variant='outlined'
              >
                Global Admin Dashboard
              </Button>
            )}
          </>
        )}
      </ShopAsModalContent>
    </ShopAsModalBase>
  );
};

ShopAsModal.propTypes = {
  dispensaries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  isGlobalAdmin: PropTypes.bool.isRequired,
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};

export default ShopAsModal;
