import camelize from 'camelize';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithToast } from './base';
import {
  newToastNotification,
  ToastType,
} from '../../dashboard/components/ToastNotifications/toasts';
import { camelToSnake, snakeString } from '../../dashboard/utils';
import joinArray from '../../utils/joinArray';

const CANIX_API_KEY_URL = 'api/vendor/:vendorId/integrations/canix/api_key/';
const CANIX_FACILITIES_URL =
  'api/vendor/:vendorId/integrations/canix/facilities/';
const CANIX_FILTER_SELLABLE_URL =
  'api/vendor/:vendorId/integrations/canix/:canixBatchId/filter_sellable/';
const CANIX_IMPORT_HISTORY_URL =
  'api/vendor/:vendorId/integrations/canix/batches/';
const CANIX_LOCATIONS_URL =
  'api/vendor/:vendorId/integrations/canix/:facilityId/locations/';
const CANIX_MAPPED_LOCATIONS_URL =
  'api/vendor/:vendorId/integrations/canix/map_facilities/';
const CANIX_START_IMPORT_URL = 'api/vendor/:vendorId/integrations/canix/start';

export const integrationsApi = createApi({
  reducerPath: 'integrations',
  baseQuery: baseQueryWithToast,
  endpoints: (builder) => ({
    filterCanixSellable: builder.mutation({
      query: ({ canixBatchId, vendorId }) => ({
        url: CANIX_FILTER_SELLABLE_URL.replace(
          ':canixBatchId',
          canixBatchId
        ).replace(':vendorId', vendorId),
        method: 'POST',
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          const { errors = [] } = data;

          if (errors.length) {
            newToastNotification({
              body: joinArray(errors),
              toastType: ToastType.ERROR,
            });
          } else {
            newToastNotification({
              body: 'Filtering sellable!',
              toastType: ToastType.SUCCESS,
            });
          }
        } catch {
          /* */
        }
      },
    }),
    getCanixApiKey: builder.query({
      query: ({ vendorId }) => CANIX_API_KEY_URL.replace(':vendorId', vendorId),
      transformResponse: camelize,
    }),

    getCanixBatches: builder.query({
      providesTags: ['CanixBatches'],
      query: ({ filters, ordering, page, pageSize, vendorId }) => ({
        method: 'GET',
        params: {
          ...camelToSnake(filters),
          page,
          page_size: pageSize,
          ...(() => {
            if (!ordering?.length) {
              return {};
            }
            const { desc, id } = ordering[0];
            return {
              sort_by: snakeString(id),
              sort_order: desc ? 'desc' : 'asc',
            };
          })(),
        },
        url: CANIX_IMPORT_HISTORY_URL.replace(':vendorId', vendorId),
      }),
      transformResponse: camelize,
    }),

    getCanixFacilities: builder.query({
      query: ({ vendorId }) =>
        CANIX_FACILITIES_URL.replace(':vendorId', vendorId),
      transformResponse: camelize,
    }),

    getCanixLocations: builder.query({
      query: ({ facilityId, vendorId }) =>
        CANIX_LOCATIONS_URL.replace(':facilityId', facilityId).replace(
          ':vendorId',
          vendorId
        ),
      transformResponse: camelize,
    }),

    getCanixMappedLocations: builder.query({
      query: ({ vendorId }) =>
        CANIX_MAPPED_LOCATIONS_URL.replace(':vendorId', vendorId),
      transformResponse: camelize,
    }),

    getImportResults: builder.query({
      query: ({ vendorId, importId }) => ({
        url: `/vendor/${vendorId}/integrations/${importId}/view-import-results`,
        method: 'POST',
      }),
      transformResponse: (response) => {
        const sortByName = (a, b) => {
          const nameA = a.existingStrain
            ? a.existingStrain.name
            : a.name || a.productName || '';
          const nameB = b.existingStrain
            ? b.existingStrain.name
            : b.name || b.productName || '';
          return nameA.localeCompare(nameB);
        };

        // Sort strains
        response.newStrains.sort(sortByName);
        response.updatedStrains.sort(sortByName);

        // Sort products
        response.newProducts.sort(sortByName);
        response.updatedProducts.sort(sortByName);

        // Sort prepacks
        response.newPrePacks.sort(sortByName);
        response.updatedPrePacks.sort(sortByName);

        // Sort weight based
        response.newBuckets.sort(sortByName);
        response.updatedBuckets.sort(sortByName);

        return response;
      },
    }),

    mapCanixLocation: builder.mutation({
      query: ({
        active,
        facilityId,
        lastImport,
        license,
        roomId,
        stockLocationId,
        updateExisting,
        vendorId,
      }) => ({
        url: CANIX_MAPPED_LOCATIONS_URL.replace(':vendorId', vendorId),
        method: 'POST',
        body: {
          active,
          facility_id: facilityId,
          last_import: lastImport,
          license,
          room_id: roomId,
          stock_location_id: stockLocationId,
          update_existing: updateExisting,
        },
      }),
      onQueryStarted: async ({ vendorId }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          const { locationMapping, errors = [] } = camelize(data);

          if (errors.length) {
            newToastNotification({
              body: joinArray(errors),
              toastType: ToastType.ERROR,
            });
          } else {
            newToastNotification({
              body: 'Facility is successfully mapped!',
              toastType: ToastType.SUCCESS,
            });
            dispatch(
              integrationsApi.util.updateQueryData(
                'getCanixMappedLocations',
                { vendorId },
                (draft) => Object.assign(draft, { locationMapping })
              )
            );
          }
        } catch {
          /* */
        }
      },
    }),

    saveCanixApiKey: builder.mutation({
      query: ({ apiKey, vendorId }) => ({
        url: CANIX_API_KEY_URL.replace(':vendorId', vendorId),
        method: 'POST',
        body: { api_key: apiKey },
      }),
      onQueryStarted: async ({ vendorId }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          const { api_key: apiKey, errors = [] } = data;

          if (errors.length) {
            newToastNotification({
              body: joinArray(errors),
              toastType: ToastType.ERROR,
            });
          } else {
            newToastNotification({
              body: 'API Key is successfully updated!',
              toastType: ToastType.SUCCESS,
            });
            dispatch(
              integrationsApi.util.updateQueryData(
                'getCanixApiKey',
                { vendorId },
                (draft) => Object.assign(draft, { apiKey })
              )
            );
          }
        } catch {
          /* */
        }
      },
    }),

    startCanixImport: builder.mutation({
      invalidatesTags: ['CanixBatches'],
      query: ({ locationIds, vendorId }) => ({
        url: CANIX_START_IMPORT_URL.replace(':vendorId', vendorId),
        method: 'POST',
        body: { location_ids: locationIds },
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          const { errors = [] } = camelize(data);

          if (errors.length) {
            newToastNotification({
              body: joinArray(errors),
              toastType: ToastType.ERROR,
            });
          } else {
            newToastNotification({
              body: 'Import is successfully started!',
              toastType: ToastType.SUCCESS,
            });
          }
        } catch {
          /* */
        }
      },
    }),

    updateCanixMappedLocation: builder.mutation({
      query: ({ vendorId, ...updates }) => ({
        url: CANIX_MAPPED_LOCATIONS_URL.replace(':vendorId', vendorId),
        method: 'PATCH',
        body: { ...camelToSnake(updates) },
      }),
      onQueryStarted: async ({ vendorId }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          const { locationMapping, errors = [] } = camelize(data);

          if (errors.length) {
            newToastNotification({
              body: joinArray(errors),
              toastType: ToastType.ERROR,
            });
          } else {
            newToastNotification({
              body: 'Facility is successfully updated!',
              toastType: ToastType.SUCCESS,
            });
            dispatch(
              integrationsApi.util.updateQueryData(
                'getCanixMappedLocations',
                { vendorId },
                (draft) => Object.assign(draft, { locationMapping })
              )
            );
          }
        } catch {
          /* */
        }
      },
    }),
  }),
});

export const {
  useFilterCanixSellableMutation,

  useGetCanixApiKeyQuery,
  useGetCanixBatchesQuery,
  useGetCanixFacilitiesQuery,
  useGetCanixLocationsQuery,
  useGetCanixMappedLocationsQuery,

  useGetImportResultsQuery,
  useLazyGetImportResultsQuery,

  useMapCanixLocationMutation,
  useSaveCanixApiKeyMutation,
  useStartCanixImportMutation,
  useUpdateCanixMappedLocationMutation,
} = integrationsApi;
