import {
  Button,
  IconLoadingSpinner,
  Select,
  TextField,
  Typography,
  helperTextTypography,
  mediumBodyLargeTypography,
  pxToRem,
  spacing2,
  spacing4,
  spacing5,
} from '@leaftrade/leaftrade-component-library';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useStorefrontState } from '../../../hooks/useReduxState';
import {
  addTimedBanner,
  clearCartAfterCheckout,
  getShopperLocations,
  postCheckoutData,
  setLocationFromCart,
  toggleAddBankAccountModal,
} from '../../../store/actions/storefrontActions';
import { CheckoutActionsLeafPayWrapper } from './CheckoutActions.styles';

const CheckoutActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cart, checkout, shopper, vendor } = useStorefrontState();
  const { multiCartSummary } = cart;
  const { canPlaceOrder, isAdmin, isPostingCheckoutData, leafPayData } =
    checkout;
  const { currentLocation } = shopper;
  const { slug } = vendor;

  const defaultAccount =
    leafPayData?.bankAccounts.find((account) => account.default)?.id ||
    leafPayData?.bankAccounts[0]?.id ||
    null;

  const [orderNotes, setOrderNotes] = useState('');
  const [selectedAccount, setSelectedAccount] = useState(defaultAccount);

  // eslint-disable-next-line no-nested-ternary
  const ableToOrder = !canPlaceOrder
    ? false
    : canPlaceOrder && leafPayData.isEnabled
      ? leafPayData.bankAccounts?.length > 0
      : true;

  const showMessage = ({ messages, preventAutoClose, severity }) => {
    messages.forEach((message) => {
      dispatch(
        addTimedBanner({
          message,
          preventAutoClose,
          severity,
        })
      );
    });
  };

  const handlePostCheckout = (isRequest = false) => {
    dispatch(
      postCheckoutData({
        data: {
          action: isRequest ? 'request' : 'order',
          bank_account: leafPayData.isEnabled ? Number(selectedAccount) : null,
          'note-message': orderNotes,
        },
        onSuccess: async ({
          errors,
          success_messages: successMessages,
          warning_messages: warningMessages,
        }) => {
          if (errors?.length > 0) {
            showMessage({
              messages: errors,
              preventAutoClose: true,
              severity: 'error',
            });
          }
          if (warningMessages?.length > 0) {
            showMessage({
              messages: warningMessages,
              timeout: 7000,
              severity: 'warning',
            });
          }
          if (successMessages?.length > 0) {
            await dispatch(clearCartAfterCheckout());
            showMessage({ messages: successMessages, severity: 'success' });
            const newCart = multiCartSummary?.carts?.find(
              (cartSummary) =>
                Number(cartSummary.locationId) !== Number(currentLocation.id)
            );

            if (newCart) {
              await dispatch(getShopperLocations({ slug }));
              await dispatch(
                setLocationFromCart({
                  locationId: newCart?.locationId,
                  dispensaryId: shopper.dispensary.id,
                  vendorSlug: slug,
                })
              );
            }

            const targetPath = newCart
              ? `/storefront/sellers/${slug}/cart/`
              : `/storefront/sellers/${slug}/`;
            await navigate(targetPath);
          }
        },
        slug,
      })
    );
  };

  return (
    <div data-testid='checkout-actions--component--div'>
      <Typography
        bodyStyle={helperTextTypography}
        italic
        sx={{ padding: `${spacing4} 0` }}
      >
        Submitted order quantities are subject to the seller&apos;s packaged
        inventory availability and are not always guaranteed. You will be
        notified if the vendor makes any changes to your order.
      </Typography>
      {isPostingCheckoutData ? (
        <Typography
          align='center'
          bodyStyle={mediumBodyLargeTypography}
          sx={{
            padding: `${spacing2} 0`,
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            margin: `${spacing5} auto`,
            '& > div': {
              marginLeft: spacing5,
            },
          }}
        >
          Processing order...
          <IconLoadingSpinner />
        </Typography>
      ) : (
        <>
          {leafPayData.isEnabled && (
            <CheckoutActionsLeafPayWrapper>
              <Typography bodyStyle={helperTextTypography} italic>
                This vendor requires you to pay for your order via the Leaf
                Trade platform.{' '}
                {leafPayData.bankAccounts.length > 0 &&
                  'Please select your account below.'}
              </Typography>
              {leafPayData.bankAccounts.length > 0 && (
                <Select
                  label='Select Account'
                  options={leafPayData.bankAccounts.map((account) => ({
                    label: account.displayName,
                    selected: account.id === selectedAccount,
                    value: account.id,
                  }))}
                  onSelect={({ value }) => {
                    setSelectedAccount(value);
                  }}
                  sx={{
                    maxWidth: pxToRem(650),
                    margin: '0 auto',
                    width: '100%',
                  }}
                />
              )}
              {isAdmin && (
                <Button
                  onClick={() => {
                    dispatch(toggleAddBankAccountModal({ isOpen: true }));
                  }}
                  sx={{ margin: '0 auto', width: pxToRem(200) }}
                  variant='secondary'
                >
                  Add Account
                </Button>
              )}
              {!isAdmin && (
                <Typography bodyStyle={helperTextTypography} italic>
                  Please contact your account administrator to add or activate a
                  bank account.
                </Typography>
              )}
            </CheckoutActionsLeafPayWrapper>
          )}
          {ableToOrder && (
            <Button
              onClick={() => handlePostCheckout(false)}
              sx={{ margin: `${spacing5} auto`, width: pxToRem(200) }}
            >
              Order
            </Button>
          )}
          <TextField
            label='Message'
            message='Cart message for your team members'
            multiline
            onChange={({ target }) => {
              setOrderNotes(target.value);
            }}
            value={orderNotes}
          />
          <Button
            onClick={() => handlePostCheckout(true)}
            sx={{ margin: `${spacing5} auto`, width: pxToRem(200) }}
            variant={canPlaceOrder ? 'secondary' : 'primary'}
          >
            {canPlaceOrder ? 'Request Cart' : 'Submit For Approval'}
          </Button>
        </>
      )}
      <Typography
        align='center'
        bodyStyle={helperTextTypography}
        italic
        sx={{ padding: `${spacing2} 0` }}
      >
        You will be able to send a message to the vendor after placing your
        order.
      </Typography>
      {leafPayData.isEnabled && (
        <Typography
          bodyStyle={helperTextTypography}
          italic
          sx={{ padding: `${spacing4} 0` }}
        >
          Leaf Pay™ is 100% free for buyers on Leaf Trade. No hidden fees.
          Payment options through Leaf Pay are subject to an eligibility check
          and are available in all states. All transactions are federally
          compliant and secure. No information will be shared with sellers or
          any outside party.
        </Typography>
      )}
    </div>
  );
};

export default CheckoutActions;
