import {
  IconDot,
  ListMenu,
  pxToRem,
  slugifyString,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  PageHeadersFilterContainer,
  PageHeadersFiltersContainer,
} from './PageHeaderFilters.styles';

const PageHeaderFilters = ({ filterOptions }) => {
  const location = useLocation();
  const queryParamsParsed = queryString.parse(location.search);

  return (
    <PageHeadersFiltersContainer data-testid='page-header-filters--component--div'>
      {filterOptions.map((filter) => {
        const filterInQuery = queryParamsParsed[filter.key];
        return (
          <PageHeadersFilterContainer key={filter.title}>
            <ListMenu
              hasClose
              hideChildren
              onClear={filter.clear}
              options={filter.options}
              placeholder={filter.title}
              showClear={!!filterInQuery && filter.showClear}
              isOverflowHidden
              sx={{
                '& > div': {
                  width: pxToRem(250),
                  zIndex: 1,
                },
                '& [data-testid="list-menu--options--div"]': {
                  minHeight: pxToRem(160),
                  '& > div': {
                    '& [data-testid="checkbox--component--input"]': {
                      overflow: 'hidden',
                    },
                  },
                },
              }}
              testingId={`page-header-filters--${slugifyString(
                filter.title
              )}--list-menu`}
              title={filter.title}
              variant='secondary'
            />
            {filterInQuery && <IconDot />}
          </PageHeadersFilterContainer>
        );
      })}
    </PageHeadersFiltersContainer>
  );
};

PageHeaderFilters.propTypes = {
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      clear: PropTypes.func,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          action: PropTypes.func,
          isChecked: PropTypes.bool,
          label: PropTypes.string,
          level: PropTypes.number,
          type: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
      ),
      title: PropTypes.string,
    })
  ).isRequired,
};

export default PageHeaderFilters;
