import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  // axiosDeleteData,
  axiosGetData,
  axiosGetParallelData,
  axiosPostData,
  axiosPutData,
  buildQueryStringFromParams,
} from '../../helpers/axios';

export const addAllItemsToOrderCreate = createAsyncThunk(
  'addAllItemsToOrderCreate',
  async ({ vendorId, orderId, data, onSuccess }, { rejectWithValue }) => {
    try {
      const responses = await Promise.all(
        data.variant_ids.map((variant) =>
          axiosPostData({
            url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/stage-items/`,
            data: {
              variant_ids: [variant],
            },
          })
        )
      );

      if (onSuccess) {
        onSuccess(responses);
      }

      return responses;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const addDiscountToOrderCreate = createAsyncThunk(
  'addDiscountToOrderCreate',
  async ({ vendorId, orderId, data, onSuccess }, { rejectWithValue }) =>
    axiosPutData({
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/bulk-discount/`,
      data,
      onSuccess,
    })
);

export const addItemToOrderCreate = createAsyncThunk(
  'addItemToOrderCreate',
  async ({ vendorId, orderId, data, onSuccess }, { rejectWithValue }) =>
    axiosPostData({
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/stage-items/`,
      data,
      onSuccess,
    })
);
export const deleteAllItemsFromOrderCreate = createAsyncThunk(
  'deleteAllItemsFromOrderCreate',
  async (
    { vendorId, orderId, orderedItemIds, onSuccess },
    { rejectWithValue }
  ) => {
    try {
      const responses = await Promise.all(
        orderedItemIds.map((orderedItemId) =>
          axiosPostData({
            url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/item/${orderedItemId}/delete/`,
          })
        )
      );

      if (onSuccess) {
        onSuccess(responses);
      }

      return responses;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteItemFromOrderCreate = createAsyncThunk(
  'deleteItemFromOrderCreate',
  async (
    { vendorId, orderId, orderedItemId, onSuccess },
    { rejectWithValue }
  ) =>
    axiosPostData({
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/item/${orderedItemId}/delete/`,
      onSuccess,
    })
);

export const getOrderCreateLocations = createAsyncThunk(
  'getOrderCreateLocations',
  async ({ dispensaryId, vendorId, onSuccess }, { rejectWithValue }) =>
    axiosGetData({
      onSuccess,
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/customers/${dispensaryId}/locations`,
    })
);

export const getOrderCreateProductVariants = createAsyncThunk(
  'getOrderCreateProductVariants',
  async (
    { orderId, vendorId, requestParams, onSuccess },
    { rejectWithValue }
  ) =>
    axiosGetData({
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/variants/${
        requestParams ? `?${buildQueryStringFromParams(requestParams)}` : ''
      }`,
      onSuccess,
    })
);

export const getOrderCreateStockLocations = createAsyncThunk(
  'getOrderCreateStockLocations',
  async ({ vendorId, onSuccess }, { rejectWithValue }) =>
    axiosGetData({
      onSuccess,
      rejectWithValue,
      url: ` /dashboard/vendor/${vendorId}/stock-locations/`,
    })
);

export const getOrderGridInfo = createAsyncThunk(
  'getOrderGridInfo',
  async ({ onSuccess, orderId, vendorId }, { rejectWithValue }) =>
    axiosGetData({
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/grid/`,
      onSuccess,
    })
);

export const getOrderGridItems = createAsyncThunk(
  'getOrderGridItems',
  async (
    { onSuccess, orderId, vendorId, requestParams },
    { rejectWithValue }
  ) =>
    axiosGetData({
      onSuccess,
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/grid-items/${
        requestParams ? `?${buildQueryStringFromParams(requestParams)}` : ''
      }`,
    })
);

export const getOrderInfo = createAsyncThunk(
  'getOrderInfo',
  async ({ requestParams, orderId, vendorId }, { rejectWithValue }) =>
    axiosGetParallelData({
      getParams: {
        urls: [
          `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/`,
          `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/filters/`,
          `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/items/${requestParams['per-page'] ? `?per-page=${requestParams['per-page']}` : ''}`,
          `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/variants/${
            requestParams ? `?${buildQueryStringFromParams(requestParams)}` : ''
          }`,
        ],
        keys: ['orderInfo', 'orderFilters', 'orderItems', 'orderVariants'],
      },
      rejectWithValue,
    })
);

export const getOrderPaginatedDrawerItems = createAsyncThunk(
  'getOrderPaginatedDrawerItems',
  async ({ orderId, page, perPage, vendorId }, { rejectWithValue }) =>
    axiosGetData({
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/items?page=${page || 1}&per-page=${perPage || 24}`,
    })
);

export const getLocationPermissions = createAsyncThunk(
  'getLocationPermissions',
  async ({ stockLocationId, vendorId }, { rejectWithValue }) =>
    axiosGetData({
      rejectWithValue,
      url: `/dashboard/api/vendor/${vendorId}/stock-location/${stockLocationId}/location-permissions/`,
    })
);

export const postOrderCreate = createAsyncThunk(
  'postOrderCreate',
  async ({ vendorId, data, onSuccess }, { rejectWithValue }) =>
    axiosPostData({
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/`,
      data,
      onSuccess,
    })
);

export const setAddDiscountActiveTab = createAction('setAddDiscountActiveTab');

export const setAddDiscountMonetaryDiscount = createAction(
  'setAddDiscountMonetaryDiscount'
);

export const setAddDiscountPercentageDiscount = createAction(
  'setAddDiscountPercentageDiscount'
);

export const setOrderCreateParams = createAction('setOrderCreateParams');

export const setOrderCreateProductVariantsPageParams = createAction(
  'setOrderCreateProductVariantsPageParams'
);

export const setOrderCreateQuantitiesPageParams = createAction(
  'setOrderCreateQuantitiesPageParams'
);

export const setOrderCreateSelectedBrands = createAction(
  'setOrderCreateSelectedBrands'
);

export const setOrderCreateSelectedCategories = createAction(
  'setOrderCreateSelectedCategories'
);

export const setOrderCreateSelectedCustomer = createAction(
  'setOrderCreateSelectedCustomer'
);

export const setOrderCreateSelectedLocation = createAction(
  'setOrderCreateSelectedLocation'
);

export const setOrderCreateSelectedStockLocation = createAction(
  'setOrderCreateSelectedStockLocation'
);

export const setVendorId = createAction('setVendorId');

export const toggleAddDiscountModal = createAction('toggleAddDiscountModal');

export const toggleOrderCreateModal = createAction('toggleOrderCreateModal');

export const updateGridItemPrice = createAsyncThunk(
  'updateGridItemPrice',
  async (
    { vendorId, orderId, orderItemId, data, onError, onSuccess },
    { rejectWithValue }
  ) =>
    axiosPutData({
      data,
      onError,
      onSuccess,
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/item/${orderItemId}/update-price/`,
    })
);

export const updateGridItemQuantity = createAsyncThunk(
  'updateGridItemQuantity',
  async (
    { itemId, vendorId, orderId, data, onSuccess, onError },
    { rejectWithValue }
  ) =>
    axiosPutData({
      data,
      onError,
      onSuccess,
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/item/${itemId}/update-quantity/`,
    })
);

export const updateGridTotals = createAsyncThunk(
  'updateGridTotals',
  async (
    { vendorId, orderId, onSuccess, requestParams },
    { rejectWithValue }
  ) =>
    axiosGetData({
      onSuccess,
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/totals/${
        requestParams ? `?${buildQueryStringFromParams(requestParams)}` : ''
      }`,
    })
);

export const validateOrderItems = createAsyncThunk(
  'validateOrderItems',
  async ({ vendorId, orderId, onSuccess }, { rejectWithValue }) =>
    axiosGetData({
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/validate-grid/`,
      onSuccess,
    })
);
