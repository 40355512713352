import {
  IconArrowRight,
  IconChevronLeft,
  IconChevronRight,
  Typography,
  spacing3,
  spacing5,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useStorefrontState } from '../../../../../../hooks/useReduxState';
import {
  formChildCategoryUrl,
  getProductFilterTypeFromCategoryName,
} from '../../../../../utils';
import {
  HeaderMobileNavChildPanelBack,
  HeaderMobileNavPanelActions,
  HeaderMobileNavPanelShopAll,
  HeaderMobileNavPanelContainer,
} from '../HeaderMobileNavPanel.styles';
import { addTimedBanner } from '../../../../../../store/actions/storefrontActions';

const HeaderMobileNavPanelSellersBrands = ({
  activeSection,
  closeAllPanels,
  setIsMobileNavOpen,
}) => {
  const dispatch = useDispatch();
  const { cart, navigation, vendor } = useStorefrontState();
  const { selectedCart } = cart;
  const { sections } = navigation;
  const [isChildPanelOpen, setIsChildPanelOpen] = useState({
    cannabis: false,
    nonTHCHempDerived: false,
    ancillary: false,
  });

  const closeAllChildPanels = () => {
    setIsChildPanelOpen({
      cannabis: false,
      nonTHCHempDerived: false,
      ancillary: false,
    });
  };

  const activeNavigationSection = sections.find(
    (section) => section.name.toLowerCase() === activeSection
  );

  const activeChildPanel = Object.keys(isChildPanelOpen).find(
    (key) => isChildPanelOpen[key] === true
  );

  const handleOpenChildPanel = (panel) => {
    setIsChildPanelOpen({
      cannabis: false,
      nonTHCHempDerived: false,
      ancillary: false,
      [panel]: true,
    });
  };

  const handleLinkClick = () => {
    closeAllChildPanels();
    closeAllPanels();
    setIsMobileNavOpen(false);
    if (selectedCart.quantity && selectedCart.quantity > 0) {
      dispatch(
        addTimedBanner({
          id: `${vendor.name}-${selectedCart?.locationName}-cart-saved`,
          message: `Your cart on ${vendor.name} for ${selectedCart?.locationName} was saved.`,
          severity: 'success',
        })
      );
    }
  };

  if (!activeNavigationSection) {
    return null;
  }

  const activeChildPanelCategory = activeNavigationSection.categories?.find(
    (category) => category.name === activeChildPanel
  );

  return (
    <HeaderMobileNavPanelContainer
      data-testid={`header-mobile-nav-panel-${activeSection}--component--div`}
    >
      {Object.values(isChildPanelOpen).some((val) => val === true) &&
      activeChildPanelCategory ? (
        <HeaderMobileNavPanelContainer data-testid='header-mobile-nav-panel--child-panel--div'>
          <HeaderMobileNavChildPanelBack
            onClick={closeAllChildPanels}
            type='button'
          >
            <IconChevronLeft />
            {activeSection}
          </HeaderMobileNavChildPanelBack>
          <Typography
            classes={['h7']}
            component='span'
            sx={{
              padding: `${spacing5} ${spacing5} ${spacing3}`,
              margin: 0,
              textTransform: 'capitalize',
            }}
          >
            {activeChildPanelCategory.name}
          </Typography>
          {activeSection === 'sellers' && (
            <HeaderMobileNavPanelShopAll
              to={{
                pathname: `/storefront/sellers`,
                search: `?product_types_filters=${getProductFilterTypeFromCategoryName(
                  activeChildPanelCategory.name.toLowerCase()
                )}`,
              }}
              onClick={handleLinkClick}
            >
              Shop All {activeChildPanelCategory.name} {activeSection}
              <IconArrowRight />
            </HeaderMobileNavPanelShopAll>
          )}
          <HeaderMobileNavPanelActions>
            {activeChildPanelCategory.children.map((child) => (
              <Link
                key={child.name}
                to={formChildCategoryUrl(child, activeSection).url}
                onClick={handleLinkClick}
              >
                {child.name}
              </Link>
            ))}
          </HeaderMobileNavPanelActions>
        </HeaderMobileNavPanelContainer>
      ) : (
        <>
          <Typography
            classes={['h7']}
            component='span'
            sx={{
              padding: `${spacing5} ${spacing5} ${spacing3}`,
              margin: 0,
              textTransform: 'capitalize',
            }}
          >
            {activeSection}
          </Typography>
          {activeNavigationSection.shopAll && (
            <HeaderMobileNavPanelShopAll
              to={activeNavigationSection.shopAll}
              onClick={handleLinkClick}
            >
              Shop All {activeSection}
              <IconArrowRight />
            </HeaderMobileNavPanelShopAll>
          )}
          <HeaderMobileNavPanelActions>
            {activeNavigationSection.categories.map((category) => (
              <>
                {category.name !== 'hemp' && (
                  <button
                    key={category.name}
                    type='button'
                    onClick={() => handleOpenChildPanel(category.name)}
                  >
                    {category.name}
                    <IconChevronRight />
                  </button>
                )}
              </>
            ))}
          </HeaderMobileNavPanelActions>
        </>
      )}
    </HeaderMobileNavPanelContainer>
  );
};

HeaderMobileNavPanelSellersBrands.propTypes = {
  activeSection: PropTypes.string.isRequired,
  closeAllPanels: PropTypes.func.isRequired,
  setIsMobileNavOpen: PropTypes.func.isRequired,
};

export default HeaderMobileNavPanelSellersBrands;
