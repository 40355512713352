import { createSlice } from '@reduxjs/toolkit';
import brandPagesState, { emptyPage } from '../states/brandPagesState';
import {
  addTile,
  downloadBrandPage,
  editTile,
  performPageAction,
  removeExistingTile,
  removeTile,
  resetPage,
  restorePage,
  restorePageDetails,
  restoreTile,
  savePageDetails,
  saveTile,
  setCreateCampaignModal,
  setFilters,
  setIsFilesUploading,
  setIsPageDetailsValidationActive,
  setLoadingTile,
  setPage,
  setPageDetailsError,
  setPageTileError,
  setPageTilesValidation,
  setVendorsList,
} from '../actions/brandPagesActions';
import { handleRejected } from '../../helpers';
import objectToCamelCase from '../../utils/objectToCamelCase';

const setIsTileLoading = (state, { id, isLoading }) =>
  state.page.pageTiles.map((tile) =>
    tile.id === id
      ? {
          ...tile,
          isLoading,
        }
      : tile
  );

export const brandPagesSlice = createSlice({
  name: 'brandPages',
  initialState: brandPagesState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setCreateCampaignModal, (state, action) => ({
      ...state,
      createCampaignModal: { ...state.createCampaignModal, ...action.payload },
    }));
    builder.addCase(setVendorsList, (state, action) => ({
      ...state,
      vendorsList: action.payload,
    }));
    builder.addCase(setFilters, (state, action) => ({
      ...state,
      tableFilters: { ...state.tableFilters, ...action.payload },
    }));
    builder.addCase(performPageAction.pending, (state) => ({
      ...state,
      isPageActionLoading: true,
    }));
    builder.addCase(performPageAction.fulfilled, (state, action) => ({
      ...state,
      isPageActionLoading: false,
      updatedPage: action.payload.brand_page,
    }));
    builder.addCase(performPageAction.rejected, (state, action) =>
      handleRejected({ ...state, isPageActionLoading: false }, action)
    );
    builder.addCase(setPage, (state, action) => ({
      ...state,
      page: { ...state.page, ...action.payload },
      isPageRestored: false,
    }));
    builder.addCase(savePageDetails.pending, (state) => ({
      ...state,
      isPageLoading: true,
    }));

    builder.addCase(savePageDetails.fulfilled, (state, action) => ({
      ...state,
      downloadedPage: objectToCamelCase(action.payload?.brand_page),
      isPageLoading: false,
    }));
    builder.addCase(savePageDetails.rejected, (state, action) =>
      handleRejected({ ...state, isPageLoading: false }, action)
    );
    builder.addCase(setIsFilesUploading, (state, action) => ({
      ...state,
      isFileUploading: action.payload,
    }));

    builder.addCase(downloadBrandPage.pending, (state) => ({
      ...state,
      downloadedPage: null,
      isPageLoading: true,
    }));
    builder.addCase(downloadBrandPage.fulfilled, (state, action) => {
      const camelCasePage = objectToCamelCase(action.payload?.brand_page);
      return {
        ...state,
        downloadedPage: objectToCamelCase(action.payload?.brand_page),
        page: {
          ...camelCasePage,
          pageTiles:
            camelCasePage.pageTiles.length % 2
              ? [
                  ...camelCasePage.pageTiles,
                  {
                    id: -(
                      Math.max(
                        ...camelCasePage.pageTiles.map((i) => Math.abs(i.id))
                      ) + 1
                    ),
                    ...(action.payload || {}),
                  },
                ]
              : camelCasePage.pageTiles,
        },
        isPageLoading: false,
      };
    });
    builder.addCase(downloadBrandPage.rejected, (state, action) =>
      handleRejected({ ...state, isPageLoading: false }, action)
    );
    builder.addCase(resetPage, (state) => ({
      ...state,
      page: emptyPage,
      downloadedPage: null,
    }));
    builder.addCase(restorePage, (state) => ({
      ...state,
      page: state.downloadedPage || emptyPage,
      isPageRestored: true,
      isPageDetailsValidationActive: false,
      pageTilesValidation: {},
      pageTilesErrors: {},
    }));

    builder.addCase(restorePageDetails, (state) => {
      const { pageTiles, ...pageDetails } = state.downloadedPage || emptyPage;
      return {
        ...state,
        page: {
          ...state.page,
          ...pageDetails,
        },
        isPageDetailsValidationActive: false,
      };
    });

    builder.addCase(restoreTile, (state, action) => ({
      ...state,
      page: {
        ...state.page,
        pageTiles: state.page.pageTiles.map((i) => {
          if (i.id === action.payload) {
            return state.downloadedPage.pageTiles.find(
              (i) => i.id === action.payload
            );
          }
          return i;
        }),
      },
      pageTilesValidation: {
        ...state.pageTilesValidation,
        [action.payload]: false,
      },
      pageTilesErrors: { [action.payload]: false },
    }));
    builder.addCase(addTile, (state, action) => ({
      ...state,
      page: {
        ...state.page,
        pageTiles: [
          ...state.page.pageTiles,
          {
            id: state.page.pageTiles.length
              ? -(
                  Math.max(...state.page.pageTiles.map((i) => Math.abs(i.id))) +
                  1
                )
              : -1,
            ...(action.payload || {}),
          },
        ],
      },
      isPageRestored: false,
    }));
    builder.addCase(removeTile, (state, action) => ({
      ...state,
      page: {
        ...state.page,
        pageTiles: state.page.pageTiles.filter(
          ({ id }) => id !== action.payload
        ),
      },
      downloadedPage: {
        ...state.downloadedPage,
        pageTiles: state.downloadedPage.pageTiles.filter(
          ({ id }) => id !== action.payload
        ),
      },
    }));
    builder.addCase(editTile, (state, action) => ({
      ...state,
      page: {
        ...state.page,
        pageTiles: state.page.pageTiles.map((i) =>
          i.id === action.payload.id
            ? {
                ...i,
                ...action.payload,
                attributes: {
                  ...(i.attributes || {}),
                  ...(action.payload.attributes || {}),
                },
              }
            : i
        ),
      },
    }));

    builder.addCase(setLoadingTile, (state, action) => ({
      ...state,
      page: {
        ...state.page,
        pageTiles: setIsTileLoading(state, action.payload),
      },
    }));

    builder.addCase(saveTile.pending, (state, action) => ({
      ...state,
      page: {
        ...state.page,
        pageTiles: setIsTileLoading(state, {
          id: action.meta.arg.id,
          isLoading: true,
        }),
      },
    }));
    builder.addCase(saveTile.fulfilled, (state, action) => {
      const isNewTile = !action.meta.arg.isUpdate;
      const savedTile = objectToCamelCase(action.payload.brand_page_tile);
      const savedTileId = isNewTile ? action.meta.arg.id : savedTile.id;
      return {
        ...state,
        page: {
          ...state.page,
          pageTiles: state.page.pageTiles.map((tile) =>
            tile.id === savedTileId ? objectToCamelCase(savedTile) : tile
          ),
        },
        downloadedPage: {
          ...state.downloadedPage,
          pageTiles: isNewTile
            ? [...state.downloadedPage.pageTiles, savedTile]
            : state.downloadedPage.pageTiles.map((i) =>
                i.id === savedTileId ? savedTile : i
              ),
        },
      };
    });
    builder.addCase(saveTile.rejected, (state, action) =>
      handleRejected(
        {
          ...state,
          page: {
            ...state.page,
            pageTiles: setIsTileLoading(state, {
              id: action.meta.arg.id,
              isLoading: false,
            }),
          },
        },
        action
      )
    );
    builder.addCase(removeExistingTile.pending, (state, action) => ({
      ...state,
      page: {
        ...state.page,
        pageTiles: setIsTileLoading(state, {
          id: action.meta.arg.id,
          isLoading: true,
        }),
      },
    }));
    builder.addCase(removeExistingTile.fulfilled, (state, action) => ({
      ...state,
      page: {
        ...state.page,
        pageTiles: state.page.pageTiles.filter(
          (tile) => tile.id !== action.meta.arg.id
        ),
      },
      downloadedPage: {
        ...state.downloadedPage,
        pageTiles: state.downloadedPage.pageTiles.filter(
          (tile) => tile.id !== action.meta.arg.id
        ),
      },
    }));
    builder.addCase(removeExistingTile.rejected, (state, action) => ({
      ...state,
      page: {
        ...state.page,
        pageTiles: setIsTileLoading(state, {
          id: action.meta.arg.id,
          isLoading: false,
        }),
      },
    }));
    builder.addCase(setIsPageDetailsValidationActive, (state, action) => ({
      ...state,
      isPageDetailsValidationActive: action.payload,
    }));
    builder.addCase(setPageTilesValidation, (state, action) => ({
      ...state,
      pageTilesValidation: { ...state.pageTilesValidation, ...action.payload },
    }));
    builder.addCase(setPageDetailsError, (state, action) => ({
      ...state,
      isPageDetailsError: action.payload,
    }));
    builder.addCase(setPageTileError, (state, action) => ({
      ...state,
      pageTilesErrors: { ...state.pageTilesErrors, ...action.payload },
    }));
  },
});
