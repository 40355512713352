import {
  bodySmallTypography,
  Button,
  Card,
  IconSearch,
  spacing6,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { NoResultsWrapper, NoResultsIconWrapper } from './NoResults.styles';

const NoResults = ({ onClear, resultsType }) => (
  <NoResultsWrapper data-testid='no-results--component--div'>
    <Card
      paddingSize='large'
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        gap: spacing6,
        width: 'auto',
      }}
    >
      <NoResultsIconWrapper className='noResults__icon'>
        <IconSearch />
      </NoResultsIconWrapper>
      <Typography component='h6'>
        We couldn&apos;t find what you&apos;re looking for
      </Typography>
      <Typography bodyStyle={bodySmallTypography}>
        There aren&apos;t any {resultsType} that match your search. Please try
        again!
      </Typography>
      <Button onClick={onClear}>Clear your search</Button>
    </Card>
  </NoResultsWrapper>
);

NoResults.propTypes = {
  onClear: PropTypes.func,
  resultsType: PropTypes.string,
};

export default NoResults;
