import {
  bodySmallTypography,
  boldBodyLargeTypography,
  Button,
  IconLoadingSpinner,
  Modal,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import {
  addTimedBanner,
  clearCart,
  setLocationFromCart,
  toggleCartClearModal,
} from '../../../../store/actions/storefrontActions';

const ClearCartModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cart, shopper, vendor } = useStorefrontState();
  const { clearCartModal, selectedCart } = cart;
  const { slug } = vendor;
  const { dispensary } = shopper;

  const clearCartModalJsx = {
    actions: !clearCartModal.isClearingCart ? (
      <>
        <Button
          onClick={() =>
            dispatch(
              toggleCartClearModal({
                isOpen: false,
              })
            )
          }
          variant='secondary'
        >
          Cancel
        </Button>
        <Button
          onClick={() =>
            dispatch(
              clearCart({
                locationIds: [selectedCart?.locationId],
                onSuccess: ({ carts }) => {
                  if (carts?.length > 0) {
                    dispatch(
                      setLocationFromCart({
                        dispensaryId: dispensary.id,
                        locationId: carts[0].locationId,
                        vendorSlug: slug,
                      })
                    );
                  } else {
                    navigate(`/storefront/sellers/${slug}`);
                  }
                  dispatch(
                    addTimedBanner({
                      id: 'cart-cleared',
                      message: 'Cart Cleared',
                      severity: 'success',
                    })
                  );
                },
                slug,
              })
            )
          }
          variant='destructive'
        >
          Clear Cart
        </Button>
      </>
    ) : (
      <Button variant='destructive'>
        <IconLoadingSpinner />
      </Button>
    ),
    header: (
      <Typography bodyStyle={boldBodyLargeTypography} gutterBottom>
        Confirm Clear Cart?
      </Typography>
    ),
    body: (
      <Typography bodyStyle={bodySmallTypography} gutterBottom>
        Are you sure you want to remove all items from the selected cart? (
        {selectedCart?.locationName} location)
      </Typography>
    ),
  };

  return (
    <Modal
      actions={clearCartModalJsx.actions}
      header={clearCartModalJsx.header}
      hideClose={clearCartModal.isClearingCart}
      onClose={() =>
        dispatch(
          toggleCartClearModal({
            isOpen: false,
          })
        )
      }
      open={clearCartModal.isOpen}
      testingId='clear-cart-modal--component--div'
    >
      {clearCartModalJsx.body}
    </Modal>
  );
};

export default ClearCartModal;
