import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  axiosDeleteData,
  axiosGetData,
  axiosPostData,
  axiosPutData,
} from '../../helpers/axios';

export const setCreateCampaignModal = createAction('setCreateCampaignModal');
export const setVendorsList = createAction('setVendorsList');
export const setFilters = createAction('setFilters');

export const performPageAction = createAsyncThunk(
  'performPageAction',
  async ({ data, onSuccess, url, onError }, { rejectWithValue }) =>
    axiosPutData({
      data,
      onSuccess,
      onError,
      rejectWithValue,
      url,
    })
);

export const setPage = createAction('setPage');
export const setIsFilesUploading = createAction('setIsFilesUploading');

export const savePageDetails = createAsyncThunk(
  'savePageDetails',
  async ({ data, onSuccess, url, isUpdate, onError }, { rejectWithValue }) => {
    const axiosFunction = isUpdate ? axiosPutData : axiosPostData;
    return axiosFunction({
      data,
      onSuccess,
      onError,
      rejectWithValue,
      url,
    });
  }
);

export const downloadBrandPage = createAsyncThunk(
  'downloadBrandPage',
  async ({ onSuccess, url, onError }, { rejectWithValue }) =>
    axiosGetData({
      onSuccess,
      onError,
      rejectWithValue,
      url,
    })
);

export const resetPage = createAction('resetPage');
export const restorePage = createAction('restorePage');
export const restorePageDetails = createAction('restorePageDetails');
export const restoreTile = createAction('restoreTile');
export const addTile = createAction('addTile');
export const editTile = createAction('editTile');

export const saveTile = createAsyncThunk(
  'saveTile',
  async ({ data, onSuccess, url, isUpdate, onError }, { rejectWithValue }) => {
    const axiosFunction = isUpdate ? axiosPutData : axiosPostData;
    return axiosFunction({
      data,
      onSuccess,
      onError,
      rejectWithValue,
      url,
    });
  }
);

export const setLoadingTile = createAction('setLoadingTiles');

export const removeTile = createAction('removeTile');

export const removeExistingTile = createAsyncThunk(
  'removeExistingTile',
  async ({ data, onSuccess, url, onError }, { rejectWithValue }) =>
    axiosDeleteData({
      data,
      onSuccess,
      onError,
      rejectWithValue,
      url,
    })
);

export const setIsPageDetailsValidationActive = createAction(
  'setIsPageDetailsValidationActive'
);
export const setPageTileError = createAction('setPageTileError');
export const setPageDetailsError = createAction('setPageDetailsError');
export const setPageTilesValidation = createAction('setPageTilesValidation');
