import {
  Button,
  IconAdd,
  IconSubtract,
  Link,
  Typography,
  boldBodySmallTypography,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { GRID_ITEM_CSS_CLASS, STOREFRONT_MODE } from '../../constants';
import { useIsOverflow } from '../../../hooks/useIsOverflow';
import { useStorefrontState } from '../../../hooks/useReduxState';
import { getAllSellersData } from '../../../store/actions/storefrontActions';
import FavoriteToggle from '../FavoriteToggle';
import StorefrontModeBadge from './StorefrontModeBadge';
import VendorBrands from './VendorBrands';
import {
  FavoriteToggleWrapper,
  ShowMoreButton,
  ShowMoreButtonContainer,
  StyledVendorCard,
  VendorBrandsWrapper,
  VendorCardInnerWrapper,
  VendorCategoriesWrapper,
  ViewSellerLinkWrapper,
} from './VendorCard.styles';
import VendorCategories from './VendorCategories';
import VendorLogo from './VendorLogo';
import VendorName from './VendorName';

const VendorCard = ({ vendor }) => {
  const dispatch = useDispatch();
  const { sellersPageData } = useStorefrontState();
  const { favoriteVendors, isGridView } = sellersPageData;

  const [showMore, setShowMore] = useState(false);
  const [cardHasOverflow, setCardHasOverflow] = useState(false);

  const brandsRef = useRef(null);
  const brandsHasOverflow = useIsOverflow({
    ref: brandsRef,
    checkHorizontal: true,
  });

  const categoriesGridRef = useRef(null);
  const categoriesGridHasOverflow = useIsOverflow({
    ref: categoriesGridRef,
    checkHorizontal: true,
  });

  const categoriesListRef = useRef(null);
  const categoriesListHasOverflow = useIsOverflow({
    ref: categoriesListRef,
  });

  const isFavorite = favoriteVendors.includes(vendor.id);

  const handleFavoriteToggleSuccess = () => {
    dispatch(
      getAllSellersData({
        isRefreshingFavorites: true,
      })
    );
  };

  useEffect(() => {
    if (isGridView) {
      setCardHasOverflow(brandsHasOverflow || categoriesGridHasOverflow);
    } else {
      setCardHasOverflow(brandsHasOverflow || categoriesListHasOverflow);
    }
  }, [
    brandsHasOverflow,
    categoriesGridHasOverflow,
    categoriesListHasOverflow,
    isGridView,
  ]);

  return (
    <StyledVendorCard
      data-testid='vendor-card--component--div'
      isGridView={isGridView}
      className={GRID_ITEM_CSS_CLASS}
    >
      {vendor.settings_deprecated.storefront_mode !==
        STOREFRONT_MODE.PURCHASING_ACTIVE && (
        <StorefrontModeBadge
          storefrontMode={vendor.settings_deprecated.storefront_mode}
        />
      )}
      <FavoriteToggleWrapper>
        <FavoriteToggle
          isFavorite={isFavorite}
          onSuccess={handleFavoriteToggleSuccess}
          vendorId={vendor.id}
        />
      </FavoriteToggleWrapper>
      <VendorLogo
        logo={vendor.logo}
        name={vendor.name}
        noLink={
          vendor.settings_deprecated.storefront_mode ===
            STOREFRONT_MODE.COMING_SOON ||
          vendor.settings_deprecated.storefront_mode ===
            STOREFRONT_MODE.INACTIVE
        }
        slug={vendor.slug}
      />
      <VendorCardInnerWrapper isGridView={isGridView}>
        <VendorName
          name={vendor.name}
          noLink={
            vendor.settings_deprecated.storefront_mode ===
              STOREFRONT_MODE.COMING_SOON ||
            vendor.settings_deprecated.storefront_mode ===
              STOREFRONT_MODE.INACTIVE
          }
          slug={vendor.slug}
        />
        <VendorBrandsWrapper>
          {vendor.brands?.length > 0 && (
            <VendorBrands
              brands={vendor.brands}
              brandsRef={brandsRef}
              showMore={showMore}
            />
          )}
        </VendorBrandsWrapper>
        <VendorCategoriesWrapper>
          {vendor.categories?.length > 0 && (
            <VendorCategories
              categories={vendor.categories}
              categoriesGridRef={categoriesGridRef}
              categoriesListRef={categoriesListRef}
              showMore={showMore}
            />
          )}
        </VendorCategoriesWrapper>
        {isGridView ? (
          <ShowMoreButtonContainer>
            {cardHasOverflow && (
              <ShowMoreButton
                isGridView={isGridView}
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? <IconSubtract /> : <IconAdd />}
                <Typography component='p' bodyStyle={boldBodySmallTypography}>
                  Show {showMore ? 'Less' : 'More'}
                </Typography>
              </ShowMoreButton>
            )}
          </ShowMoreButtonContainer>
        ) : (
          <>
            {cardHasOverflow && (
              <ShowMoreButtonContainer>
                <ShowMoreButton
                  isGridView={isGridView}
                  onClick={() => setShowMore(!showMore)}
                >
                  {showMore ? <IconSubtract /> : <IconAdd />}
                  <Typography component='p' bodyStyle={boldBodySmallTypography}>
                    Show {showMore ? 'Less' : 'More'}
                  </Typography>
                </ShowMoreButton>
              </ShowMoreButtonContainer>
            )}
          </>
        )}
      </VendorCardInnerWrapper>
      <ViewSellerLinkWrapper isGridView={isGridView}>
        {vendor.settings_deprecated.storefront_mode ===
        STOREFRONT_MODE.COMING_SOON ? (
          <Button disabled>
            {vendor.settings_deprecated.storefront_mode ===
            STOREFRONT_MODE.COMING_SOON
              ? 'Coming Soon'
              : ' Inactive'}
          </Button>
        ) : (
          <Link
            align='center'
            asButton
            component={RouterLink}
            to={`/storefront/sellers/${vendor.slug}`}
          >
            View Seller
          </Link>
        )}
      </ViewSellerLinkWrapper>
    </StyledVendorCard>
  );
};

VendorCard.propTypes = {
  vendor: PropTypes.shape({
    brands: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    categories: PropTypes.arrayOf(PropTypes.number),
    logo: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number,
    settings_deprecated: PropTypes.shape({
      storefront_mode: PropTypes.string,
    }),
    slug: PropTypes.string,
  }).isRequired,
};

export default VendorCard;
