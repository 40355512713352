export const DEFAULT_FILTER = {
  status: 'active',
};

const productsState = {
  isProductUpdating: false,
  refreshKey: 0,
};

export default productsState;
