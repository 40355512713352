import {
  IconLoadingSpinner,
  seafoam60,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useStorefrontState } from '../../../hooks/useReduxState';
import {
  getAuthUserDispensariesAndVendors,
  getCartData,
  getCartDataSummary,
  getNavData,
  getSellerNavData,
  getShopperLocations,
  resetProductDetails,
  setHasLimitedAccess,
  setLocationFromLoadData,
  setNavigationLinks,
  setProductId,
  setShopper,
  setVendorSlug,
} from '../../../store/actions/storefrontActions';
import { getParamFromMatchPatch } from '../../../utils';
import CallFallback from '../CallFallback';
import ShopAsModal from '../modals/ShopAsModal';
import {
  AppBase,
  SpinnerWrapper,
} from '../../../shared/styles/LoadData.styles';

const LoadData = ({
  children,
  currentLocation: currentLocationFromProps,
  currentShopper,
  dispensaries: dispensariesFromProps,
  hasLimitedAccess,
  isPublicRoute,
  isVendorPreview,
  loggedOutAccess,
  navigationLinks,
  vendors: vendorsFromProps,
}) => {
  const browserLocation = useLocation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { cart, navigation, shopper, statusError } = useStorefrontState();
  const { selectedCart } = cart;
  const { token } = selectedCart;
  const { brands, vendors } = navigation;
  const { currentLocation } = shopper;

  const isCartPage = browserLocation?.pathname?.includes('/cart');
  const isCheckoutPage = browserLocation?.pathname?.includes('/checkout');
  const isQuickOrderPage = browserLocation?.pathname?.includes('/quick-order');
  const isProductPage = browserLocation?.search?.includes('variant');
  let productId;

  let path = '/storefront/sellers/:slug';
  if (isPublicRoute) {
    path = '/public/:slug';
  } else if (isCartPage) {
    path = '/storefront/sellers/:slug/cart';
  } else if (isCheckoutPage) {
    path = '/storefront/sellers/:slug/checkout';
  } else if (isVendorPreview && isProductPage) {
    path = '/storefront/sellers/vendor-preview/:slug/:productId';
  } else if (isVendorPreview) {
    path = '/storefront/sellers/vendor-preview/:slug';
  } else if (isQuickOrderPage) {
    path = '/storefront/sellers/:slug/quick-order';
  } else if (isProductPage) {
    path = '/storefront/sellers/:slug/:productId';
  }

  const hasCurrentShopper =
    currentShopper !== undefined && !!Object.keys(currentShopper).length;
  const hasCurrentLocationFromProps =
    currentLocationFromProps !== undefined &&
    !!Object.keys(currentLocationFromProps).length;
  const hasCurrentLocation =
    currentLocation !== undefined && !!Object.keys(currentLocation).length;
  const hasRestOfData =
    hasCurrentLocation &&
    !!(
      brands !== undefined &&
      !!Object.keys(brands).length &&
      vendors !== undefined &&
      !!Object.keys(vendors).length
    );

  const slug = getParamFromMatchPatch({
    location: browserLocation,
    path,
    paramKey: 'slug',
  });

  if (isProductPage) {
    productId = getParamFromMatchPatch({
      location: browserLocation,
      path,
      paramKey: 'productId',
    });
  }

  useEffect(() => {
    if (hasLimitedAccess) {
      dispatch(setHasLimitedAccess(true));
    } else {
      dispatch(setHasLimitedAccess(false));
    }
  }, [hasLimitedAccess]);

  useEffect(() => {
    if (hasCurrentShopper) {
      dispatch(setShopper(currentShopper));
    }
  }, [currentShopper]);

  useEffect(() => {
    if (hasCurrentLocationFromProps) {
      dispatch(setLocationFromLoadData(currentLocationFromProps));
    }
  }, [currentLocationFromProps]);

  useEffect(() => {
    if (navigationLinks && navigationLinks.length) {
      dispatch(setNavigationLinks(navigationLinks));
    }
  }, [navigationLinks]);

  useEffect(() => {
    if ((hasCurrentLocation || hasLimitedAccess) && !loggedOutAccess) {
      dispatch(getAuthUserDispensariesAndVendors());
      if (!hasLimitedAccess) {
        dispatch(getNavData());
      }
    }
  }, [hasCurrentLocation, hasLimitedAccess]);

  useEffect(() => {
    dispatch(setVendorSlug(slug));
    if (slug && !isPublicRoute) {
      dispatch(getSellerNavData({ slug }));
      if (!isVendorPreview) {
        dispatch(getShopperLocations({ slug }));
      }
    }
  }, [slug]);

  useEffect(() => {
    if (!hasLimitedAccess) {
      if (slug && !token && (isCartPage || isQuickOrderPage)) {
        dispatch(getCartDataSummary({ slug }));
      }
      if (slug && token) {
        dispatch(
          getCartData({
            getNotices: isCartPage,
            setIsLoading: true,
            slug,
            token,
          })
        );
      }
    }
  }, [slug, token, isCartPage, isQuickOrderPage]);

  useEffect(() => {
    if (productId) {
      dispatch(setProductId(productId));
    } else {
      dispatch(resetProductDetails());
    }
  }, [productId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // this is weird, but we randomly get this error with our tooltips
  // it's actually safe to ignore (https://stackoverflow.com/a/50387233)
  // this hides the debug overlay for it
  // going to look for an alternative solution (prob bump react version in component library and bring in MUI )
  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  if (statusError) {
    return (
      <CallFallback
        hasLimitedAccess={hasLimitedAccess}
        statusError={statusError}
      />
    );
  }

  if (!hasCurrentLocation && !hasLimitedAccess) {
    return (
      <ShopAsModal
        dispensaries={dispensariesFromProps}
        isGlobalAdmin={currentShopper.isSuperuser}
        vendors={vendorsFromProps}
      />
    );
  }

  if (hasCurrentLocation && !hasLimitedAccess && !hasRestOfData) {
    return (
      <div data-testid='load-data--component--div'>
        <SpinnerWrapper data-testid='load-data--spinner-wrapper--div'>
          <IconLoadingSpinner
            mainLineColor={seafoam60.hex}
            secondaryLineColor='transparent'
            width='128px'
            height='128px'
            lineWidth='16px'
            rotateTiming='1.5s'
          />
        </SpinnerWrapper>
      </div>
    );
  }

  return (
    <div data-testid='load-data--component--div'>
      <AppBase>{children}</AppBase>
    </div>
  );
};

LoadData.propTypes = {
  children: PropTypes.node.isRequired,
  currentLocation: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    dispensary: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
  currentShopper: PropTypes.shape({
    dispensary: PropTypes.shape({
      multi_cart_enabled: PropTypes.bool.isRequired,
      react_cart_enabled: PropTypes.bool.isRequired,
      quick_order_enabled: PropTypes.bool.isRequired,
    }).isRequired,
    isCustomer: PropTypes.bool.isRequired,
    isStaff: PropTypes.bool.isRequired,
    isSuperuser: PropTypes.bool.isRequired,
    isVendor: PropTypes.bool.isRequired,
  }).isRequired,
  dispensaries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  hasLimitedAccess: PropTypes.bool,
  isPublicRoute: PropTypes.bool,
  isVendorPreview: PropTypes.bool,
  loggedOutAccess: PropTypes.bool,
  navigationLinks: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};

export default LoadData;
