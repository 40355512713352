/* eslint-disable no-nested-ternary */
import {
  Button,
  Link,
  IconLoadingSpinner,
  pxToRem,
} from '@leaftrade/leaftrade-component-library';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useStorefrontState } from '../../../hooks/useReduxState';
import {
  getCartData,
  setLocationFromCart,
  toggleQuickOrderClearModal,
} from '../../../store/actions/storefrontActions';
import { QuickOrderActionsWrapper } from './QuickOrderActions.styles';

const QuickOrderActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cart, quickOrderData, shopper, vendor } = useStorefrontState();
  const { multiCartSummary, selectedCart } = cart;
  const { token } = selectedCart;
  const { editingAllItemQuantity, totalItems } = quickOrderData;
  const { currentLocation, dispensary } = shopper;
  const { slug } = vendor;

  const [isProcessingReview, setIsProcessingReview] = useState(false);

  const handleClearClick = () => {
    dispatch(
      toggleQuickOrderClearModal({
        isOpen: true,
      })
    );
  };

  const handleReviewAllCartsClick = async () => {
    setIsProcessingReview(true);
    if (
      !multiCartSummary.carts.find(
        (cartSummary) =>
          Number(cartSummary.locationId) === Number(currentLocation.id)
      )
    ) {
      await dispatch(
        setLocationFromCart({
          dispensaryId: dispensary.id,
          locationId: multiCartSummary.carts[0].locationId,
          vendorSlug: slug,
        })
      );
    }
    await dispatch(
      getCartData({
        slug,
        token: multiCartSummary.carts[0].token,
      })
    );
    await navigate(`/storefront/sellers/${slug}/cart/`);
    setIsProcessingReview(false);
  };

  return (
    <QuickOrderActionsWrapper data-testid='quick-order-actions--component--div'>
      {totalItems > 0 && (
        <Button onClick={handleClearClick} variant='secondary'>
          Clear
        </Button>
      )}
      <Link
        asButton
        buttonVariant='secondary'
        onClick={() => {
          dispatch(getCartData({ setIsLoading: true, slug, token }));
        }}
        to={`/storefront/sellers/${slug}`}
        component={RouterLink}
      >
        Back to Storefront
      </Link>
      {totalItems > 0 && (
        <Button
          disabled={isProcessingReview || editingAllItemQuantity.isEditing}
          sx={{ marginLeft: 'auto', width: pxToRem(150) }}
          variant='primary'
          onClick={handleReviewAllCartsClick}
        >
          {isProcessingReview ? (
            <IconLoadingSpinner />
          ) : editingAllItemQuantity.isEditing ? (
            'Updating Carts...'
          ) : (
            'Review All Carts'
          )}
        </Button>
      )}
    </QuickOrderActionsWrapper>
  );
};

export default QuickOrderActions;
