export const serializeFilters = (filters, serializationMap) => {
  const serialized = {};
  for (const [key, value] of Object.entries(filters)) {
    if (value === null || value === undefined) {
      continue;
    }
    if (Array.isArray(value)) {
      if (serializationMap[key] === 'commaDelimited') {
        serialized[key] = `${key}=${encodeURIComponent(value.join(','))}`;
      } else {
        serialized[key] = value
          .map((v) => `${key}=${encodeURIComponent(v)}`)
          .join('&');
      }
    } else {
      serialized[key] = `${key}=${encodeURIComponent(value)}`;
    }
  }
  return Object.values(serialized).join('&');
};

export const serializeOrdering = (ordering) => {
  if (ordering[0]) {
    return `${ordering[0].desc ? '-' : ''}${ordering[0].id}`;
  }
};
