import queryString from 'query-string';
import { Select } from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const PageHeaderSort = ({ onSort, sortOptions }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParamsParsed = queryString.parse(location.search);
  const existingSortByQuery = queryParamsParsed.sort;

  const [sortByQuery, setSortByQuery] = useState(existingSortByQuery || '');
  const [selectionMade, setSelectionMade] = useState(false);

  const handleSortBy = () => {
    if (sortByQuery) {
      if (sortByQuery === existingSortByQuery) {
        return;
      }
      queryParamsParsed.sort = sortByQuery;
    } else {
      delete queryParamsParsed.sort;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(queryParamsParsed)}`,
      { replace: true }
    );
    onSort(queryString.stringify(queryParamsParsed));
  };

  const handleSelect = (option) => {
    setSortByQuery(option?.value || '');
    setSelectionMade(true);
  };

  useEffect(() => {
    if (selectionMade) {
      handleSortBy();
    }
  }, [sortByQuery, selectionMade]);

  return (
    <Select
      hasClose
      options={sortOptions}
      placeholder='Sort by'
      onSelect={handleSelect}
      shape='rounded'
      showFooter
      sx={{
        flex: 1,
      }}
      testingId='page-header-sort--sort-by--select'
      title='Sort by'
      variant='secondary'
    />
  );
};

PageHeaderSort.propTypes = {
  onSort: PropTypes.func.isRequired,
  sortOptions: PropTypes.array.isRequired,
};

export default PageHeaderSort;
