import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  axiosDeleteData,
  axiosGetData,
  axiosPatchData,
  axiosPostData,
  axiosPostQueue,
} from '../../helpers/axios';
import moment from 'moment';
import { camelToSnake } from '../../dashboard/utils';

const ACTION_PREFIX = 'cannabisBatchActions/';

const formatCannabisBatchData = (data) => {
  const payload = camelToSnake(data);

  [
    'expiration_date',
    'harvest_date',
    'lab_results_date',
    'manufacture_date',
  ].forEach((dateField) => {
    if (payload[dateField] && payload[dateField] instanceof Date) {
      payload[dateField] = moment(payload[dateField]).format('YYYY-MM-DD');
    }
  });

  ['thc_delta8', 'thc_delta9'].forEach((key) => {
    if (payload[key]) {
      payload[key.replace('delta', 'delta_')] = payload[key];
      delete payload[key];
    }
  });

  return payload;
};

// we do want to use isNaN here to see if the id will coerce to an int,
// or if it is just a label for uniqueness (ie, a string)
export const isPersisted = (id) => !!id && !isNaN(id);

export const addBatchVariant = createAction(`${ACTION_PREFIX}addBatchVariant`);

export const addTerpene = createAction(`${ACTION_PREFIX}addTerpene`);

export const addSampleCase = createAction(`${ACTION_PREFIX}addSampleCase`);

export const createCannabisBatch = createAsyncThunk(
  `${ACTION_PREFIX}createCannabisBatch`,
  async ({ data, onSuccess, url }, { rejectWithValue }) =>
    axiosPostData({
      data: formatCannabisBatchData(data),
      onSuccess,
      rejectWithValue,
      url,
    })
);

export const createCannabisBatchCOA = createAsyncThunk(
  `${ACTION_PREFIX}createCannabisBatchCOA`,
  async ({ data, onSuccess, url }, { rejectWithValue }) =>
    axiosPostQueue({
      data,
      onSuccess,
      rejectWithValue,
      url,
    })
);

export const deleteCannabisBatchCOA = createAsyncThunk(
  `${ACTION_PREFIX}deleteCannabisBatchCOA`,
  async ({ onSuccess, url }, { rejectWithValue }) =>
    axiosDeleteData({
      onSuccess,
      rejectWithValue,
      url,
    })
);

export const getCannabisBatch = createAsyncThunk(
  `${ACTION_PREFIX}getCannabisBatch`,
  async ({ onSuccess, url }, { rejectWithValue }) =>
    axiosGetData({
      onSuccess,
      rejectWithValue,
      url,
    })
);

export const reanalyzeCannabisBatchCOA = createAsyncThunk(
  `${ACTION_PREFIX}reanalyzeCannabisBatchCOA`,
  async ({ data, onSuccess, url }, { rejectWithValue }) =>
    axiosPostData({
      data,
      onSuccess,
      rejectWithValue,
      url,
    })
);

export const removeBatchVariant = createAction('removeBatchVariant');

export const removeTerpene = createAction('removeTerpene');

export const saveCannabisBatch = createAsyncThunk(
  `${ACTION_PREFIX}saveCannabisBatch`,
  async ({ data, onSuccess, url }, { rejectWithValue }) =>
    axiosPatchData({
      data: formatCannabisBatchData(data),
      onSuccess,
      rejectWithValue,
      url,
    })
);

export const setPayload = createAction(
  `${ACTION_PREFIX}setPayload`,
  (payload) => {
    const serializedPayload = { ...payload };
    Object.keys(serializedPayload).forEach((key) => {
      if (serializedPayload[key] instanceof Date) {
        serializedPayload[key] = serializedPayload[key].getTime();
      }
    });
    return {
      payload: serializedPayload,
    };
  }
);

export const updateBatchVariant = createAction(
  `${ACTION_PREFIX}updateBatchVariant`
);

export const updateTerpene = createAction(`${ACTION_PREFIX}updateTerpene`);
