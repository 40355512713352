import {
  bodySmallTypography,
  Link,
  spacing3,
  spacing5,
  Typography,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useStorefrontState } from '../../../../hooks/useReduxState';

const HeaderVendorPreview = () => {
  const browserLocation = useLocation();
  const { vendor, vendorPreview } = useStorefrontState();
  const { slug } = vendor;
  const { customer, location } = vendorPreview;

  const isSelectCustomerLocationPage = browserLocation.pathname.includes(
    '/select-customer-location'
  );

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: '1',
        padding: `0 ${spacing5}`,
        gap: spacing3,
      }}
    >
      {!isSelectCustomerLocationPage && customer?.name && location?.name && (
        <>
          <Typography bodyStyle={bodySmallTypography}>
            Previewing Storefront as {customer.name} - {location.name}
          </Typography>
          <Link
            asButton
            component={RouterLink}
            buttonSize='xs'
            buttonVariant='secondary'
            to={`/storefront/sellers/vendor-preview/${slug}/select-customer-location`}
          >
            Routes Preview Location
          </Link>
        </>
      )}
    </div>
  );
};

export default HeaderVendorPreview;
