import { Modal } from '@leaftrade/leaftrade-component-library';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import { LoadMoreWrapper } from '../../../routes/Seller/Seller.styles';
import {
  getQuickOrderProducts,
  toggleQuickOrderAddModal,
} from '../../../../store/actions/storefrontActions';
import NoResults from '../../NoResults';
import PageLoader from '../../../../shared/components/PageLoader';
import ProductCard from '../../ProductCard';
import ProductCardBatchQueues from '../../ProductCardBatchQueues';
import SellerHeader from '../../SellerHeader';

const AddToQuickOrderModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { ref, inView } = useInView();
  const { quickOrderData, vendor } = useStorefrontState();
  const { addToQuickOrderModal } = quickOrderData;
  const { hasAdditionalProduct, isLoadingQuickOrderProducts, products } =
    addToQuickOrderModal;
  const { settings, slug } = vendor;
  const { batchQueuesEnabled, isGridView } = settings;

  const [pageNumber, setPageNumber] = useState(0);

  const queryParamsParsed = queryString.parse(location.search);

  const handleClearQueryParams = () => {
    navigate(location.pathname, { replace: true });
    dispatch(
      getQuickOrderProducts({
        slug,
      })
    );
  };

  const addToQuickOrderModalJsx = {
    header: <SellerHeader isQuickOrder />,
    body: (
      <>
        {isLoadingQuickOrderProducts ? (
          <PageLoader message='Loading' />
        ) : (
          <>
            {products?.length ? (
              products.map((product) => (
                <>
                  {batchQueuesEnabled ? (
                    <ProductCardBatchQueues
                      key={product.id}
                      product={product}
                      slug={slug}
                    />
                  ) : (
                    <ProductCard
                      key={product.id}
                      product={product}
                      slug={slug}
                    />
                  )}
                </>
              ))
            ) : (
              <NoResults
                onClear={handleClearQueryParams}
                resultsType='products'
              />
            )}
          </>
        )}
        {hasAdditionalProduct && (
          <LoadMoreWrapper ref={ref}>
            <PageLoader message='Fetching more results' />
          </LoadMoreWrapper>
        )}
      </>
    ),
  };

  useEffect(() => {
    if (addToQuickOrderModal.isOpen) {
      dispatch(
        getQuickOrderProducts({
          queryString: queryString.stringify(queryParamsParsed),
          slug,
        })
      );
    }
  }, [addToQuickOrderModal.isOpen]);

  useEffect(() => {
    if (inView && hasAdditionalProduct) {
      queryParamsParsed.page_num = pageNumber + 1;

      dispatch(
        getQuickOrderProducts({
          isLoadingMore: true,
          page: pageNumber + 1,
          slug,
          queryString: queryString.stringify(queryParamsParsed),
        })
      );

      setPageNumber(pageNumber + 1);
    }
  }, [inView]);

  return (
    <Modal
      actions={addToQuickOrderModalJsx.actions}
      header={addToQuickOrderModalJsx.header}
      hideClose={addToQuickOrderModal.isClearingQuickOrder}
      onClose={() =>
        dispatch(
          toggleQuickOrderAddModal({
            isOpen: false,
          })
        )
      }
      open={addToQuickOrderModal.isOpen}
      sx={{
        '& [data-testid="modal--header--div"]': {
          paddingLeft: '32px',
        },
        '& [data-testid="modal--content--div"]': {
          maxHeight: 'calc(100% - 180px)',
          display: 'flex',
          flexDirection: isGridView ? 'row' : 'column',
          flexWrap: isGridView ? 'wrap' : 'nowrap',
          justifyContent: isGridView ? 'center' : 'flex-start',
          gap: '1rem',
        },
      }}
      testingId='add-item-quick-order-modal--component--div'
      modalWidth='90vw'
    >
      {addToQuickOrderModalJsx.body}
    </Modal>
  );
};

export default AddToQuickOrderModal;
