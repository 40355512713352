/* eslint-disable no-nested-ternary */
import {
  Card,
  IconArrowRight,
  Link,
  bodySmallTypography,
  interaction60,
  neutral5,
  neutral80,
  pxToRem,
  spacing3,
  spacing4,
  spacing5,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { formChildCategoryUrl } from '../../../../utils';

const HeaderNavCategoryCard = ({
  activeSection,
  activeCategory,
  handleLinkClick,
}) => (
  <Card
    sx={{
      padding: 0,
      background: neutral5.hex,
      margin: '0 1rem',
      minWidth: pxToRem(255),
      maxWidth: pxToRem(625),
      maxHeight: pxToRem(305),
      flexWrap: 'wrap',
      overflow: 'auto',
      placeContent: 'unset',
      alignSelf: 'stretch',
      height: 'unset',
      alignItems: 'flex-start',
      alignContent: 'flex-start',
      width:
        activeCategory.children.length <= 6
          ? pxToRem(255)
          : activeCategory.children.length > 6 &&
              activeCategory.children.length <= 12
            ? pxToRem(415)
            : pxToRem(625),
    }}
    testingId='header--nav-category-card--component--div'
  >
    {activeSection.name === 'Sellers' && (
      <Link
        bodyStyle={bodySmallTypography}
        classes={['h8']}
        component={RouterLink}
        noWrap
        onClick={handleLinkClick}
        sx={{
          color: interaction60.hex,
          padding: `${spacing5} ${spacing5} ${spacing3}`,
          '&:hover': {
            color: interaction60.hex,
            '& svg': {
              marginLeft: spacing4,
            },
          },
          '& svg': {
            marginLeft: spacing3,
            width: spacing5,
            height: spacing5,
            fill: interaction60.hex,
            transition: 'margin-left 0.22s ease-in-out',
          },
        }}
        to={`/storefront/sellers?product_types_filters=${activeCategory.id}`}
        TypographySx={{
          textTransform: 'capitalize',
          flexBasis: '100%',
          position: 'sticky',
          top: '0',
          background: neutral5.hex,
        }}
        underline='none'
        variant='span'
      >
        Shop All {activeCategory.name} Sellers
        <IconArrowRight />
      </Link>
    )}
    {activeCategory.children.map((child) => (
      <Link
        bodyStyle={bodySmallTypography}
        component={RouterLink}
        key={child.name}
        noWrap
        onClick={() => {
          handleLinkClick({
            slug: formChildCategoryUrl(child, activeSection.name).slug,
          });
        }}
        sx={{
          display: 'inline-block',
          color: neutral80.hex,
          padding: `${spacing3} ${spacing5}`,
          '&:hover': {
            color: neutral80.hex,
          },
        }}
        title={child.name}
        to={formChildCategoryUrl(child, activeSection.name).url}
        TypographySx={{
          flexBasis:
            activeCategory.children.length <= 6
              ? '100%'
              : activeCategory.children.length > 6 &&
                  activeCategory.children.length <= 12
                ? '50%'
                : '33%',
        }}
        underline='hover'
      >
        {child.name}
      </Link>
    ))}
  </Card>
);

export default HeaderNavCategoryCard;
