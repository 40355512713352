import { GlobalStyle } from '@leaftrade/leaftrade-component-library';
import { OptimizelyProvider, createInstance } from '@optimizely/react-sdk';
import PropTypes from 'prop-types';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Fallback from '../shared/components/Fallback';
import PageNotFound from '../shared/components/PageNotFound';
import PageWrapper from '../shared/components/PageWrapper';
import TimedBanners from '../shared/components/TimedBanners';
import { setupStore } from '../store';
import Footer from './components/Footer';
import Header from './components/Header';
import HeaderMobile from './components/HeaderMobile';
import LoadData from './components/LoadData';
import Cart from './routes/Cart';
import Checkout from './routes/Checkout';
import Landing from './routes/Landing';
import LimitedAccess from './routes/LimitedAccess';
import PrivacyPolicy from './routes/PrivacyPolicy';
import Product from './routes/Product';
import QuickOrder from './routes/QuickOrder';
import Seller from './routes/Seller';
import Sellers from './routes/Sellers';
import TermsOfService from './routes/TermsOfService';
import BrandPage from './routes/BrandPage';
import VendorPreview from './routes/VendorPreview';

const StorefrontRouter = ({
  currentLocation,
  currentShopper,
  dispensaries,
  navigationLinks,
  userId,
  vendors,
}) => {
  const optimizely = createInstance({
    sdkKey: `${process.env.OPTIMIZELY_KEY}`,
    eventBatchSize: 10,
    eventFlushInterval: 1000,
  });

  if (!currentShopper.isSuperuser) {
    TagManager.initialize({
      gtmId: `${process.env.GTM_ID}`,
    });
  }

  const currentDispensaryId = currentLocation?.dispensary?.id
    ? currentLocation.dispensary.id
    : 0;
  const currentLocationId = currentLocation?.id ? currentLocation.id : 0;

  const isPublicRoute = window.location?.pathname?.includes('/public/');
  const isVendorPreview =
    window.location?.pathname?.includes('/vendor-preview/');

  const hasLimitedAccess = isPublicRoute || isVendorPreview;

  const loggedOutAccess =
    !currentShopper.isCustomer &&
    !currentShopper.isStaff &&
    !currentShopper.isVendor &&
    !currentShopper.isSuperuser;

  return (
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={Fallback}>
        <OptimizelyProvider
          optimizely={optimizely}
          user={{
            id: userId ? userId.toString() : '0',
            attributes: {
              current_dispensary_id: currentDispensaryId,
              current_location_id: currentLocationId,
            },
          }}
        >
          <Provider store={setupStore()}>
            <GlobalStyle />
            <LoadData
              currentShopper={currentShopper || {}}
              currentLocation={currentLocation || {}}
              dispensaries={dispensaries || []}
              hasLimitedAccess={hasLimitedAccess}
              isPublicRoute={isPublicRoute}
              isVendorPreview={isVendorPreview}
              loggedOutAccess={loggedOutAccess}
              navigationLinks={navigationLinks || []}
              vendors={vendors || []}
            >
              <Header
                loggedOutAccess={loggedOutAccess}
                hasLimitedAccess={hasLimitedAccess}
              />
              <HeaderMobile
                loggedOutAccess={loggedOutAccess}
                hasLimitedAccess={hasLimitedAccess}
              />
              <TimedBanners />
              <Routes>
                <Route path='/' element={<Landing />} />
                <Route path='/storefront' element={<Navigate to='/' />} />
                <Route path='/storefront/sellers' element={<Sellers />} />
                <Route path='/storefront/sellers/:slug' element={<Seller />} />
                <Route
                  path='/storefront/sellers/vendor-preview/:slug'
                  element={<Seller />}
                />
                <Route
                  path='/storefront/sellers/vendor-preview/:slug/select-customer-location'
                  element={<VendorPreview />}
                />
                {currentShopper.dispensary?.quick_order_enabled && (
                  <Route
                    path='/storefront/sellers/:slug/quick-order'
                    element={
                      <PageWrapper sidePadding='small'>
                        <QuickOrder />
                      </PageWrapper>
                    }
                  />
                )}
                <Route
                  path='/storefront/sellers/:slug/cart/'
                  element={
                    <PageWrapper unsetOverflow>
                      <Cart />
                    </PageWrapper>
                  }
                />
                <Route
                  path='/storefront/sellers/:slug/checkout/'
                  element={
                    <PageWrapper unsetOverflow>
                      <Checkout />
                    </PageWrapper>
                  }
                />
                <Route
                  path='/storefront/sellers/:slug/:productId'
                  element={
                    <PageWrapper>
                      <Product />
                    </PageWrapper>
                  }
                />
                <Route
                  path='/storefront/sellers/vendor-preview/:slug/:productId'
                  element={
                    <PageWrapper>
                      <Product />
                    </PageWrapper>
                  }
                />
                <Route
                  path='/public/:slug'
                  element={<LimitedAccess isPublicRoute />}
                />
                <Route
                  path='/public/404-not-found'
                  element={
                    <PageWrapper>
                      <PageNotFound hasLimitedAccess={hasLimitedAccess} />
                    </PageWrapper>
                  }
                />
                <Route
                  path='/storefront/tos/'
                  element={
                    <PageWrapper>
                      <TermsOfService />
                    </PageWrapper>
                  }
                />
                <Route
                  path='/storefront/privacy-policy/'
                  element={
                    <PageWrapper>
                      <PrivacyPolicy />
                    </PageWrapper>
                  }
                />
                <Route
                  path='/public/:vendorName/brands/:pageUrlSegment'
                  element={<BrandPage />}
                />
                <Route
                  path='*'
                  element={
                    <PageWrapper>
                      <PageNotFound />
                    </PageWrapper>
                  }
                />
              </Routes>
              <Footer />
            </LoadData>
          </Provider>
        </OptimizelyProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

StorefrontRouter.propTypes = {
  currentLocation: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    dispensary: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  }),
  currentShopper: PropTypes.shape({
    dispensary: PropTypes.shape({
      multi_cart_enabled: PropTypes.bool,
      react_cart_enabled: PropTypes.bool,
      quick_order_enabled: PropTypes.bool,
    }),
    isCustomer: PropTypes.bool,
    isStaff: PropTypes.bool,
    isSuperuser: PropTypes.bool,
    isVendor: PropTypes.bool,
  }),
  dispensaries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  navigationLinks: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  userId: PropTypes.number,
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
};

StorefrontRouter.defaultProps = {
  currentLocation: {},
  currentShopper: {},
  dispensaries: [],
  navigationLinks: [],
  userId: 0,
  vendors: [],
};

export default StorefrontRouter;
