import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithToast } from './base';
import camelize from 'camelize';

export const advancedAnalyticsApi = createApi({
  reducerPath: 'advanced-analytics',
  baseQuery: baseQueryWithToast,
  tagTypes: ['AnalyticsUser', 'AnalyticsReport'],
  endpoints: (builder) => ({
    getAdvancedAnalyticsUserList: builder.query({
      query: () => 'api/advanced-analytics/users/',
      transformResponse: (response) =>
        camelize(response.advanced_analytics_users),
      providesTags: ['AnalyticsUser'],
    }),
    getAdvancedAnalyticsUserStatus: builder.query({
      query: (userId) => ({
        url: `api/advanced-analytics/users/${userId}/`,
        validateStatus: (response) =>
          response.status === 200 || response.status === 404,
      }),
      transformResponse: (response, meta) => {
        if (meta.response.status === 404) {
          return {
            userAnalyticsActive: false,
          };
        }

        return camelize(response.advanced_analytics_user);
      },
      providesTags: (result, error, userId) => [
        { type: 'AnalyticsUser', id: userId },
      ],
    }),
    getAdvancedAnalyticsFullReport: builder.query({
      query: () => 'api/advanced-analytics/users/full-report/',
      transformResponse: (response) => camelize(response.analytics_data),
      providesTags: ['AnalyticsReport'],
    }),

    activateAdvancedAnalytics: builder.mutation({
      query: (userId) => ({
        url: 'api/advanced-analytics/users/',
        method: 'POST',
        body: { advanced_analytics_user: { user_id: userId } },
      }),
      invalidatesTags: (result, error, userId) => [
        'AnalyticsUser',
        'AnalyticsReport',
        { type: 'AnalyticsUser', id: userId },
      ],
    }),
    deactivateAdvancedAnalytics: builder.mutation({
      query: (userId) => ({
        url: `api/advanced-analytics/users/${userId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, userId) => [
        'AnalyticsUser',
        'AnalyticsReport',
        { type: 'AnalyticsUser', id: userId },
      ],
    }),
  }),
});

export const {
  useGetAdvancedAnalyticsUserListQuery,
  useGetAdvancedAnalyticsUserStatusQuery,
  useGetAdvancedAnalyticsFullReportQuery,
  useActivateAdvancedAnalyticsMutation,
  useDeactivateAdvancedAnalyticsMutation,
} = advancedAnalyticsApi;
