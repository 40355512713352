import {
  elevation1,
  maxMediaQueryDesktop,
  neutral0,
  rose100,
  rose60,
  spacing2,
  spacing3,
  spacing4,
  spacing5,
  spacing7,
} from '@leaftrade/leaftrade-component-library';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { headerZIndex } from '../../../shared/styles/zIndex';
import { headerBgColor } from '../../../shared/styles/Header.styles';

export const HeaderMobileBase = styled.header`
  display: none;
  ${maxMediaQueryDesktop} {
    display: block;
  }
`;

export const HeaderMobileWrapper = styled.div`
  background: ${headerBgColor};
  display: flex;
  align-items: center;
  padding: ${spacing4};
  position: sticky;
  top: 0;
  z-index: ${headerZIndex};
  color: ${neutral0.hex};
`;

export const HeaderMobileLogo = styled(Link)`
  & > img {
    height: ${spacing7};
  }
`;

export const HeaderMobileCartLink = styled(Link)`
  display: flex;
  padding: ${spacing2} ${spacing3};
  justify-content: flex-end;
  align-items: center;
  gap: ${spacing2};
  background: ${rose60.hex};
  border-radius: 90px;
  border: none;
  margin-left: auto;
  ${elevation1()}
  &:hover, &:focus, &:active {
    background: ${rose100.hex};
    text-decoration: none;
  }
  & svg {
    fill: ${neutral0.hex};
    height: ${spacing5};
    width: ${spacing5};
  }
`;
