import {
  Select,
  Typography,
  pxToRem,
} from '@leaftrade/leaftrade-component-library';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useStorefrontState } from '../../../../hooks/useReduxState';
import { setSelectedVariant } from '../../../../store/actions/storefrontActions';

const ProductDetailsVariantSelectBatchQueues = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productDetails } = useStorefrontState();
  const { selectedVariant } = productDetails;

  const variantNameBuilder = (variant) =>
    variant.stock_record.batch
      ? `${variant.name}${
          variant.case_size > 1 ? `, case of ${variant.case_size}` : ''
        }`
      : `${variant.verbose_name} ${
          variant.storefront_display_price !== null &&
          `- ${variant.storefront_display_price}`
        }`;

  const buildVariantOptions = (packageSizes) =>
    packageSizes.map((packageSize) => ({
      label: variantNameBuilder(packageSize),
      value: packageSize.id,
      level: 1,
      selected: packageSize.id === selectedVariant?.id,
      key: packageSize.id,
    }));

  const variantOptions = buildVariantOptions(productDetails.packageSizes);

  const handleVariantChange = ({ value }) => {
    const newSelectedVariant = productDetails.variants.find(
      (variant) => variant.id.toString() === value.toString()
    );
    dispatch(setSelectedVariant(newSelectedVariant));
    navigate(`?variant=${value}`, { replace: true });
  };

  return (
    <>
      {variantOptions.length > 1 ? (
        <Select
          closeOnSelect
          options={variantOptions}
          onSelect={handleVariantChange}
          shape='rounded'
          sx={{
            width: pxToRem(350),
            '& > div': {
              zIndex: 1,
            },
          }}
          testingId='product-details-variant-select-batch-queues--component--select'
          value={productDetails.selectedVariantId}
          variant='secondary'
        />
      ) : (
        <Typography>{variantOptions[0].label}</Typography>
      )}
    </>
  );
};

export default ProductDetailsVariantSelectBatchQueues;
