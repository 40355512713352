import {
  IconChevronDown,
  IconChevronUp,
  ListMenu,
  pxToRem,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toggleCartPreviewModal } from '../../../../store/actions/storefrontActions';
import { HeaderActionsLocationName } from '../../../../shared/styles/HeaderActions.styles';
import { HeaderSelectCTA } from '../../../../shared/styles/HeaderSelect.styles';

const HeaderSelect = ({ icon, menuItems, subtitle, testingId, title }) => {
  const dispatch = useDispatch();
  const nodeRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = (e) => {
    if (nodeRef.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside clicks
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const toggle = () => {
    dispatch(
      toggleCartPreviewModal({
        isOpen: false,
      })
    );
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ListMenu
        closeOnSelect
        cta={
          <HeaderSelectCTA
            hasSubtitle={subtitle}
            isOpen={isOpen}
            onClick={toggle}
            ref={nodeRef}
          >
            {icon}
            {!subtitle ? (
              <span>{title}</span>
            ) : (
              <HeaderActionsLocationName>
                <span>{title}</span>
                <span>{subtitle}</span>
              </HeaderActionsLocationName>
            )}
            {isOpen ? <IconChevronUp /> : <IconChevronDown />}
          </HeaderSelectCTA>
        }
        options={menuItems}
        optionsParentElementStyles={{
          zIndex: 999999,
        }}
        sx={{
          '& > div': {
            top: pxToRem(28),
            minWidth: pxToRem(180),
            right: title === 'Account' ? pxToRem(0) : 'unset',
            zIndex: 1,
          },
        }}
        testingId={testingId}
      />
    </>
  );
};

HeaderSelect.defaultProps = {
  subtitle: null,
  testingId: 'header--select--component--div',
};

HeaderSelect.propTypes = {
  icon: PropTypes.node.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func,
      label: PropTypes.string,
      key: PropTypes.string,
      level: PropTypes.number,
    })
  ).isRequired,
  subtitle: PropTypes.string,
  testingId: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default HeaderSelect;
