import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosPatchData } from '../../helpers/axios';

export const updateProducts = createAsyncThunk(
  'updateProducts',
  async ({ data, onSuccess, url, onError }, { rejectWithValue }) =>
    axiosPatchData({
      data,
      onSuccess,
      onError,
      rejectWithValue,
      url,
      headers: { 'Content-Type': 'application/json' },
    })
);

export const setIsProductUpdating = createAction('setIsProductUpdating');
