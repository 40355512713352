import camelize from 'camelize';
import { createSlice } from '@reduxjs/toolkit';
import {
  newToastNotification,
  ToastType,
} from '../../dashboard/components/ToastNotifications/toasts';
import {
  addBatchVariant,
  addSampleCase,
  addTerpene,
  createCannabisBatch,
  createCannabisBatchCOA,
  deleteCannabisBatchCOA,
  getCannabisBatch,
  isPersisted,
  reanalyzeCannabisBatchCOA,
  removeBatchVariant,
  removeTerpene,
  saveCannabisBatch,
  setPayload,
  updateBatchVariant,
  updateTerpene,
} from '../actions/cannabisBatchActions';
import cannabisBatchState from '../states/cannabisBatchState';
import moment from 'moment';

const formatFloats = (obj) => {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (typeof value === 'string' && value.match(/^\d*\.\d*$/g)) {
      obj[key] = parseFloat(value);
    }
  });
};

const formatDates = (cannabisBatch) => {
  return [
    'expirationDate',
    'harvestDate',
    'labResultsDate',
    'manufactureDate',
  ].forEach((field) => {
    if (cannabisBatch[field]) {
      cannabisBatch[field] = moment(cannabisBatch[field]).toDate().getTime();
    }
  });
};

const handleRejected = (state, { meta, payload: { response } }, method) => {
  newToastNotification({
    body: 'An error occurred.',
    toastType: ToastType.ERROR,
  });

  return {
    ...state,
    isLoading: {
      ...(state.isLoadingConfig || {}),
      [`${method} ${meta.arg.url}`]: false,
    },
    isCOALoading: false,
    errors: { ...(state.errors || {}), ...camelize(response?.data || {}) },
  };
};

const refreshCannabisBatchState = (state, action) => {
  const cannabisBatch = camelize(action.payload);
  formatDates(cannabisBatch);
  formatFloats(cannabisBatch);
  (cannabisBatch.batchUnits || []).forEach(formatFloats);
  cannabisBatch.batchUnits.sort((a, b) => a.id > b.id);
  (cannabisBatch.terpenes || []).forEach(formatFloats);

  const batchVariants = [];
  (cannabisBatch.batchUnits || []).forEach((batchUnit) =>
    batchUnit.variants.forEach((variant, index) =>
      batchVariants.push({
        ...batchUnit,
        ...variant,
        batchUnit,
        cannabisBatchInventoryUnitId: batchUnit.id,
        errors: {},
        isDuplicate: index !== 0,
        productVariantId: variant.id,
      })
    )
  );
  batchVariants.forEach(formatFloats);

  const newState = {
    ...state,
    ...cannabisBatch,
    batchVariants,
    dirtyBatchVariants: {},
    dirtyTerpenes: {},
    errors: {},
  };

  // if copy, clear original ids, mark all variants new and dirty
  if (action.meta.arg.copy) {
    delete newState.batchRef;
    delete newState.coas;
    newState.batchVariants.forEach((batchVariant, counter) =>
      Object.assign(batchVariant, {
        batchUnit: { ...batchVariant.batchUnit, id: null },
        cannabisBatchInventoryUnitId: null,
        id: null,
        hasOrders: false,
        productVariantId: `copy-of-${batchVariant.productVariantId || counter}`,
        pullNumber: batchVariant.pullNumber || '',
        stockId: null,
        wasImported: false,
      })
    );
    newState.dirtyBatchVariants = newState.batchVariants.reduce(
      (dirties, batchVariant) => ({
        ...dirties,
        [batchVariant.productVariantId]: batchVariant,
      }),
      {}
    );
    if (newState.terpenes) {
      newState.terpenes.forEach((terpene) => {
        Object.assign(terpene, { id: `copy-of-${terpene.id}` });
        newState.dirtyTerpenes[terpene.id] = { ...terpene };
      });
    }
  }
  // otherwise snapshot last save for batch-level attributes dirty comparison
  else {
    newState.persistedCannabisBatch = cannabisBatch;
  }

  // reset deletions
  delete newState.deletedBatchVariantIds;

  return newState;
};

export const cannabisBatchSlice = createSlice({
  name: 'cannabisBatch',
  initialState: cannabisBatchState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addBatchVariant, (state, action) => {
      const currentBatchVariantCounter = state.newBatchVariantCounter || 0;
      const batchVariant = {
        productVariantId: `new-batch-variant-${currentBatchVariantCounter}`,
      };
      const {
        payload: { product },
      } = action;
      if (product) {
        Object.assign(batchVariant, {
          productId: product.id,
          productName: product.name,
        });
      }
      const update = {
        batchVariants: [...(state.batchVariants || [])].concat(batchVariant),
        dirtyBatchVariants: {
          ...(state.dirtyBatchVariants || {}),
          [batchVariant.productVariantId]: batchVariant,
        },
        newBatchVariantCounter: currentBatchVariantCounter + 1,
      };
      return {
        ...state,
        ...update,
      };
    });
    builder.addCase(
      addSampleCase,
      (state, { payload: { productVariantId } }) => {
        const currentBatchVariantCounter = state.newBatchVariantCounter || 0;
        const update = {
          batchVariants: [...state.batchVariants],
          dirtyBatchVariants: { ...(state.dirtyBatchVariants || {}) },
          newBatchVariantCounter: currentBatchVariantCounter + 1,
        };
        const batchVariantIndex = update.batchVariants.findIndex(
          (batchVariant) => batchVariant.productVariantId === productVariantId
        );
        const { batchUnit, productId } =
          update.batchVariants[batchVariantIndex];
        const batchVariant = {
          ...batchUnit,
          batchUnit,
          cannabisBatchInventoryUnitId: batchUnit.id,
          caseSize: 1,
          isDuplicate: true,
          productId,
          productVariantId: `new-batch-variant-${currentBatchVariantCounter}`,
        };
        delete batchVariant.packageSizeId;
        update.batchVariants.splice(batchVariantIndex + 1, 0, batchVariant);
        update.dirtyBatchVariants[batchVariant.productVariantId] = batchVariant;
        return {
          ...state,
          ...update,
        };
      }
    );
    builder.addCase(addTerpene, (state, action) => {
      const currentTerpenes = [...(state.terpenes || [])];
      const currentTerpeneCounter = state.newTerpeneCounter || 0;
      const terpene = {
        id: `new-terpene-${currentTerpeneCounter}`,
        ...action.payload,
      };
      // make sure terpenes are unique by terpene type
      if (
        currentTerpenes.find(
          ({ terpeneTypeId }) => terpene.terpeneTypeId === terpeneTypeId
        )
      ) {
        return state;
      }

      const update = {
        dirtyTerpenes: {
          ...(state.dirtyTerpenes || {}),
          [terpene.id]: terpene,
        },
        newTerpeneCounter: currentTerpeneCounter + 1,
        terpenes: currentTerpenes.concat(terpene),
      };
      return {
        ...state,
        ...update,
      };
    });
    builder.addCase(createCannabisBatch.pending, (state, action) => ({
      ...state,
      isLoading: {
        ...(state.isLoadingConfig || {}),
        [`POST ${action.meta.arg.url}`]: true,
      },
    }));
    builder.addCase(createCannabisBatch.fulfilled, (state, action) => {
      window.location = action.meta.arg.redirectPath.replace(
        ':id',
        action.payload.id
      );

      return {
        ...refreshCannabisBatchState(state, action),
        isLoading: {
          ...(state.isLoadingConfig || {}),
          [`POST ${action.meta.arg.url}`]: false,
        },
      };
    });
    builder.addCase(createCannabisBatch.rejected, (state, action) =>
      handleRejected(state, action, 'POST')
    );
    builder.addCase(createCannabisBatchCOA.pending, (state, action) => ({
      ...state,
      isCOALoading: {
        ...(state.isLoadingConfig || {}),
        [`POST ${action.meta.arg.url}`]: true,
      },
    }));
    builder.addCase(createCannabisBatchCOA.fulfilled, (state, action) => {
      return {
        ...state,
        coas: Object.keys(action.payload).length
          ? [...state.coas, ...camelize(Object.values(action.payload))]
          : [...state.coas],
        isCOALoading: {
          ...(state.isLoadingConfig || {}),
          [`POST ${action.meta.arg.url}`]: false,
        },
      };
    });
    builder.addCase(createCannabisBatchCOA.rejected, (state, action) =>
      handleRejected(state, action, 'POST')
    );
    builder.addCase(deleteCannabisBatchCOA.pending, (state, action) => ({
      ...state,
      isCOALoading: {
        ...(state.isLoadingConfig || {}),
        [`DELETE ${action.meta.arg.url}`]: true,
      },
    }));
    builder.addCase(deleteCannabisBatchCOA.fulfilled, (state, action) => ({
      ...state,
      isCOALoading: {
        ...(state.isLoadingConfig || {}),
        [`DELETE ${action.meta.arg.url}`]: false,
      },
    }));
    builder.addCase(deleteCannabisBatchCOA.rejected, (state, action) =>
      handleRejected(state, action, 'DELETE')
    );
    builder.addCase(getCannabisBatch.pending, (state, action) => ({
      ...state,
      isLoading: {
        ...(state.isLoadingConfig || {}),
        [`GET ${action.meta.arg.url}`]: true,
      },
    }));
    builder.addCase(getCannabisBatch.fulfilled, (state, action) => ({
      ...refreshCannabisBatchState(state, action),
      isLoading: {
        ...(state.isLoadingConfig || {}),
        [`GET ${action.meta.arg.url}`]: false,
      },
    }));
    builder.addCase(getCannabisBatch.rejected, (state, action) =>
      handleRejected(state, action, 'GET')
    );
    builder.addCase(reanalyzeCannabisBatchCOA.pending, (state) => state);
    builder.addCase(reanalyzeCannabisBatchCOA.fulfilled, (state) => {
      newToastNotification({
        body: 'Terpenes reimporting, updated results will be available soon.',
        toastType: ToastType.SUCCESS,
      });

      return state;
    });
    builder.addCase(reanalyzeCannabisBatchCOA.rejected, (state) => {
      newToastNotification({
        body: 'An error occurred.',
        toastType: ToastType.ERROR,
      });

      return state;
    });
    builder.addCase(
      removeBatchVariant,
      (state, { payload: { productVariantId } }) => {
        const update = {
          batchVariants: [...state.batchVariants],
          dirtyBatchVariants: { ...state.dirtyBatchVariants },
        };
        const batchVariantIndex = update.batchVariants.findIndex(
          (batchVariant) => batchVariant.productVariantId === productVariantId
        );
        delete update.dirtyBatchVariants[productVariantId];
        update.batchVariants.splice(batchVariantIndex, 1);
        if (isPersisted(productVariantId)) {
          update.deletedBatchVariantIds = [
            ...(state.deletedBatchVariantIds || []),
          ].concat(productVariantId);
        }

        return {
          ...state,
          ...update,
        };
      }
    );
    builder.addCase(removeTerpene, (state, { payload: { id } }) => {
      const update = {
        dirtyTerpenes: { ...state.dirtyTerpenes },
        terpenes: [...state.terpenes],
      };
      const terpeneIndex = update.terpenes.findIndex(
        (terpene) => terpene.id === id
      );
      delete update.dirtyTerpenes[id];
      update.terpenes.splice(terpeneIndex, 1);
      if (isPersisted(id)) {
        update.deletedTerpeneIds = [...(state.deletedTerpeneIds || [])].concat(
          id
        );
      }

      return {
        ...state,
        ...update,
      };
    });
    builder.addCase(saveCannabisBatch.pending, (state, action) => ({
      ...state,
      isLoading: {
        ...(state.isLoadingConfig || {}),
        [`PATCH ${action.meta.arg.url}`]: true,
      },
    }));
    builder.addCase(saveCannabisBatch.fulfilled, (state, action) => {
      newToastNotification({
        body: 'Batch updated.',
        toastType: ToastType.SUCCESS,
      });
      return {
        ...refreshCannabisBatchState(state, action),
        isLoading: {
          ...(state.isLoadingConfig || {}),
          [`PATCH ${action.meta.arg.url}`]: false,
        },
      };
    });
    builder.addCase(saveCannabisBatch.rejected, (state, action) =>
      handleRejected(state, action, 'PATCH')
    );
    builder.addCase(setPayload, (state, action) => ({
      ...state,
      ...action.payload,
    }));
    builder.addCase(
      updateBatchVariant,
      (state, { payload: { productVariantId, delta } }) => {
        const update = {
          batchVariants: [...state.batchVariants],
          dirtyBatchVariants: { ...(state.dirtyBatchVariants || {}) },
        };
        const batchVariantIndex = update.batchVariants.findIndex(
          (batchVariant) => batchVariant.productVariantId === productVariantId
        );
        update.batchVariants[batchVariantIndex] = {
          ...update.batchVariants[batchVariantIndex],
          ...delta,
        };
        update.dirtyBatchVariants[productVariantId] = {
          ...(update.dirtyBatchVariants[productVariantId] || {}),
          ...delta,
          productVariantId,
        };

        return {
          ...state,
          ...update,
        };
      }
    );
    builder.addCase(updateTerpene, (state, { payload: { id, delta } }) => {
      const update = {
        dirtyTerpenes: { ...(state.dirtyTerpenes || {}) },
        terpenes: [...state.terpenes],
      };
      const terpeneIndex = update.terpenes.findIndex(
        (terpene) => terpene.id === id
      );
      update.terpenes[terpeneIndex] = {
        ...update.terpenes[terpeneIndex],
        ...delta,
      };
      update.dirtyTerpenes[id] = {
        ...(update.dirtyTerpenes[id] || {}),
        ...delta,
        id,
      };

      return {
        ...state,
        ...update,
      };
    });
  },
});
