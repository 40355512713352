import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  axiosGetData,
  axiosPatchData,
  axiosPostData,
  methodsMap,
} from '../../helpers/axios';

export const setFilters = createAction('setFilters');
export const refreshKey = createAction('refreshKey');

export const updateBatches = createAsyncThunk(
  'updateBatches',
  async ({ data, onSuccess, url, onError }, { rejectWithValue }) =>
    axiosPatchData({
      data,
      onSuccess,
      onError,
      rejectWithValue,
      url,
      headers: { 'Content-Type': 'application/json' },
    })
);

export const createVariants = createAsyncThunk(
  'createVariants',
  async ({ data, onSuccess, url, onError }, { rejectWithValue }) =>
    axiosPostData({
      data,
      onSuccess,
      onError,
      rejectWithValue,
      url,
      headers: { 'Content-Type': 'application/json' },
    })
);

export const fetchStockLocations = createAsyncThunk(
  'fetchStockLocations',
  async ({ data, onSuccess, url, onError }, { rejectWithValue }) =>
    axiosGetData({
      data,
      onSuccess,
      onError,
      rejectWithValue,
      url,
      headers: { 'Content-Type': 'application/json' },
    })
);

export const fetchCategories = createAsyncThunk(
  'fetchCategories',
  async ({ data, onSuccess, url, onError }, { rejectWithValue }) =>
    axiosGetData({
      data,
      onSuccess,
      onError,
      rejectWithValue,
      url,
    })
);

export const fetchContext = createAsyncThunk(
  'fetchContext',
  async ({ data, onSuccess, url, onError }, { rejectWithValue }) =>
    axiosGetData({
      data,
      onSuccess,
      onError,
      rejectWithValue,
      url,
    })
);

export const runModalAction = createAsyncThunk(
  'runModalAction',
  async ({ data, onSuccess, url, onError, method }, { rejectWithValue }) =>
    methodsMap[method]({
      data,
      onSuccess,
      onError,
      rejectWithValue,
      url,
    })
);

export const fetchTemplate = createAsyncThunk(
  'fetchTemplate',
  async ({ data, onSuccess, url, onError }, { rejectWithValue }) =>
    axiosGetData({
      data,
      onSuccess,
      onError,
      rejectWithValue,
      url,
    })
);

export const setTemplate = createAction('setTemplate');

export const saveTemplates = createAsyncThunk(
  'saveTemplates',
  async ({ data, onSuccess, url, onError }, { rejectWithValue }) =>
    axiosPatchData({
      data,
      onSuccess,
      onError,
      rejectWithValue,
      url,
      headers: { 'Content-Type': 'application/json' },
    })
);

export const fetchVariantSelectedProducts = createAsyncThunk(
  'fetchVariantSelectedProducts',
  async ({ data, onSuccess, url, onError }, { rejectWithValue }) =>
    axiosGetData({
      data,
      onSuccess,
      onError,
      rejectWithValue,
      url,
    })
);
