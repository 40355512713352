import { spacing8 } from '@leaftrade/leaftrade-component-library';

export const STOREFRONT_MODE = {
  PURCHASING_ACTIVE: 'purchasing_active',
  READ_ONLY_MENU: 'read_only_menu',
  COMING_SOON: 'coming_soon',
  INACTIVE: 'inactive',
};

export const BQ_DEBUG = 'bq_debug';

export const GRID_ITEM_WIDTH = 275;
export const MAX_GRID_WIDTH = 1200;
export const GRID_ITEM_CSS_CLASS = 'grid-item';
export const GRID_HORIZONTAL_OFFSET = `${spacing8} + ${spacing8}`;
export const GRID_LIST_VIEW_SX = {
  display: 'flex',
  flexDirection: 'column',
  '.grid-item': { margin: '0 auto' },
};
